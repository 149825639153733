import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      payment_forms: {},
      recurring_transactions: {
        saved_cards: [],
      },
      last_payment_form: null,
      amount_to_pay: {
        "balance":
        {
          "net": 0,
          "gross": 0
        },
        "invoices": {
          "net": 0,
          "gross": 0
        },
        "job": {
          "net": 0,
          "gross": 0
        },
        "total": {
          "net": 0,
          "gross": 0
        }
      },
      billingBalance: {
        "balance": {
          "net": 0,
          "gross": 0
        },
        "debt_limit": {
          "net": 0,
          "gross": 0
        },
        "possible_donation": {
          "net": 0,
          "gross": 0
        },
        "under_limit": {
          "net": 0,
          "gross": 0
        },
        "unpaid_invoices_amount": {
          "net": 0,
          "gross": 0
        },
        "jobs_to_approve": {
          "net": 0,
          "gross": 0
        }
      },
      billingBalanceArchive: {
        "balance": {
          "net": 0,
          "gross": 0
        },
        "debt_limit": {
          "net": 0,
          "gross": 0
        },
        "possible_donation": {
          "net": 0,
          "gross": 0
        },
        "under_limit": {
          "net": 0,
          "gross": 0
        },
        "unpaid_invoices_amount": {
          "net": 0,
          "gross": 0
        },
        "jobs_to_approve": {
          "net": 0,
          "gross": 0
        }
      },
      transferData: {},
      overpayment: "",
      days: "",
      comission: 0,
      country_code: 'pl',
    }
  },
  getters: {
    payment_forms(state) {
      return state.payment_forms;
    },
    method: (state) => (value) => {
      return state.payment_forms[value];
    },
    recurring_transactions(state) {
      return state.recurring_transactions;
    },
    last_payment_form(state) {
      return state.last_payment_form;
    },
    amount_to_pay(state) {
      return state.amount_to_pay;
    },
    billingBalance(state) {
      return state.billingBalance;
    },
    billingBalanceArchive(state) {
      return state.billingBalanceArchive;
    },
    transferData(state) {
      return state.transferData;
    },
    overpayment(state) {
      return state.overpayment;
    },
    comission(state) {
      return state.comission;
    },
    post_paid(state) {
      return state.post_paid;
    },
    days(state) {
      return state.days;
    },
    country_code(state) {
      return state.country_code
    }
  },
  mutations: {
    SET_PAYFORMS(state, { payment_forms }) {
      state.payment_forms = { ...payment_forms };
    },
    SET_RECURRING_TRANSACTIONS(state, { transactions }) {
      state.recurring_transactions = {
        ...transactions
      }
    },
    SET_LAST_PAYMENT_FORM(state, { payment_form }) {
      state.last_payment_form = [...payment_form];
    },
    SET_AMOUNT_TO_PAY(state, { amount_to_pay }) {
      amount_to_pay.total =
          {
            net: amount_to_pay.balance.net + amount_to_pay.invoices.net + amount_to_pay.job.net,
            gross: amount_to_pay.balance.gross + amount_to_pay.invoices.gross + amount_to_pay.job.gross
          };
      state.amount_to_pay = { ...amount_to_pay };
    },
    SET_BILLING_BALANCE(state, { billingBalance }) {
      state.billingBalance = { ...billingBalance };
    },
    SET_BILLING_BALANCE_ARCHIVE(state, { billingBalanceArchive }) {
      state.billingBalanceArchive = { ...billingBalanceArchive };
    },
    SET_TRANSFER_DATA(state, { transferData }) {
      state.transferData = transferData
    },
    SET_OVERPAYMENT(state, { overpayment }) {
      state.overpayment = overpayment;
    },
    SET_COMISSION(state, { comission }) {
      state.comission = comission;
    },
    SET_COUNTRY_CODE(state, { country_code }) {
      state.country_code = country_code;
    },
    SET_POST_PAID(state, { post_paid }) {
      state.post_paid = post_paid;
    },
    SET_DAYS(state, { days }) {
      state.days = days;
    },
  },
  actions: {
    fetchPaymentsData(context, { jobId, archive = false }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      if (jobId) {
        formData.append("job", jobId);
      }
      return new Promise((resolve, reject) => {
        axios.post(`/api/Balance/paymentsForms`, formData).then((res) => {
          context.commit("SET_PAYFORMS", { payment_forms: res.data.payment_forms });
          context.commit("SET_RECURRING_TRANSACTIONS", { transactions: res.data.recurring_transactions });
          context.commit("SET_LAST_PAYMENT_FORM", { payment_form: res.data.last_payment_form });
          context.commit("SET_AMOUNT_TO_PAY", { amount_to_pay: res.data.amount_to_pay });
          context.commit("SET_BILLING_BALANCE", { billingBalance: res.data.billing_balance });
          if (res.data.archive_client_data) {
            context.commit("SET_BILLING_BALANCE_ARCHIVE", { billingBalanceArchive: res.data.archive_client_data.billing_balance });
            if (archive) {
              context.commit("SET_PAYFORMS", { payment_forms: res.data.archive_client_data.payment_forms });
            }
          }
          context.commit("SET_OVERPAYMENT", { overpayment: res.data.overpayment });
          context.commit("SET_COMISSION", { comission: res.data.comission ? res.data.comission : 0.005 });
          context.commit("SET_POST_PAID", { post_paid: res.data.post_paid });
          context.commit("SET_DAYS", { days: res.data.days });
          context.commit("SET_COUNTRY_CODE", { country_code: res.data.country });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
    cancelCardPayment(context, transactionId) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Balance/removeRecurring/transaction_id/${transactionId}`).then((res) => {
          if (res.data.result === 'success') {
            this.state.paymentMethods.recurring_transactions.saved_cards.forEach((elem, index) => {
              if (elem.id === transactionId) {
                this.state.paymentMethods.recurring_transactions.saved_cards.splice(index, 1);
              }
            });
          }
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
    /**
     * @param {*} context 
     * @param {*} { payment_type, payment_form, amount, recurring_payment, return_url } 
     */
    addPayment(context, payload) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      for (let key in payload) {
        if (key === "payment_form") {
          if (payload.payment_type === "iaipay_payment") {
            formData.append(key, payload[key]);
          }
        } else {
          formData.append(key, payload[key]);
        }
      }
      return new Promise((resolve, reject) => {
        axios.post(`/api/Balance/addPayment`, formData).then((res) => {
          if (payload.payment_type === "iaipay_blik") {
            resolve(res.data.form)
          } else {
            window.location.href = res.data.link;
            resolve(true)
          }
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
  }
}
<template>
  <div class="line_chart d-flex flex-wrap flex-sm-nowrap" :class="className">
    <div
      class="line_chart__segment mb-4 mb-sm-0"
      v-for="item in preparedData"
      :key="item.name"
      :style="`width: ${item.part}%; min-width: 70px`"
    >
      <div
        :title="`${$t(item.label)} ${item.value}`"
        class="line_chart__line"
        :style="`width: ${item.part}%`"
      ></div>
      <span :title="`${$t(item.label)} ${item.value}`">{{
        $t(item.label)
      }}</span>
      <strong :title="`${$t(item.label)} ${item.value}`">{{
        item.value
      }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataArray: Array,
    currency: [String, Number],
    className: String,
  },
  computed: {
    preparedData() {
      const data = [];
      let totalValue = this.dataArray.reduce((total, item) => {
        return (total += parseFloat(item.value));
      }, 0);
      for (const item of this.dataArray) {
        data.push({
          label: item.name,
          value: this.$formatPrice(item.value, this.currency),
          part: (parseFloat(item.value) / totalValue) * 100,
        });
      }
      return data;
    },
  },
};
</script>

<style lang="less">
@import "../styles/variables.less";

.line_chart {
  width: 100%;
  font-size: 1.3rem;
  &__line {
    height: 8px;
    margin: 0 1px 8px;
    @media @tablet {
      width: 100% !important;
    }
  }
  &__segment {
    overflow: hidden;
    @media (max-width: 979px) {
      width: 100% !important;
    }

    span,
    strong {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      padding-right: 5px;
      cursor: help;
    }
    .line_chart__line {
      background: @color_lighter_orange;
      cursor: help;
    }
    &:nth-child(1) {
      .line_chart__line {
        background: #f88792;
      }
    }
    &:nth-child(2) {
      .line_chart__line {
        background: #ffc37e;
      }
    }
    &:nth-child(3) {
      .line_chart__line {
        background: #aae589;
      }
    }
    &:nth-child(4) {
      .line_chart__line {
        background: #85d6f9;
      }
    }
  }
}
</style>
export default [
    {
        name: "StronaGlowna",
        path: "/",
        icon: "dashboard",
        perms: []
    },
    {
        name: "CentrumSupportu",
        path: "/support",
        icon: "message",
        perms: []
    },
    {
        name: "Wydatki",
        path: "/balance",
        icon: "balance",
        perms: ["admin", "payments", "iai_orders", "finances"],
        children: [
            {
                name: "WplataSrodkow",
                path: "/payments",
                perms: ["admin", "payments"]
            },
            {
                name: "HistoriaWydatkowIZasilen",
                path: "/history",
                perms: ["admin", "payments"]
            },
            {
                name: "ZadaniaDoZatwierdzenia",
                path: "/confirm_tasks",
                perms: ["admin", "payments", "iai_orders", "finances"]
            },
        ],
    },
    {
        name: "Faktury",
        path: "/invoices",
        icon: "invoice",
        perms: ["admin", "payments"]
    },
    {
        name: "TwojeKonto",
        path: "/account",
        icon: "user",
        perms: [],
        children: [
            {
                name: "ZarzadzanieKontem",
                path: "/settings",
                perms: ["admin"]
            },
        ]
    },
    {
        name: "ShoprenterGo",
        path: "/shoprentergo",
        icon: "chart",
        perms: [],
        children: [
            {
                name: "ListOfChargedPackages",
                path: "/packages",
                perms: ["admin", "payments"]
            },
            {
                name: "ListaWplat",
                path: "/list",
                perms: ["admin", "payments"]
            },
            {
                name: "WyplataSrodkow",
                path: "/withdraw",
                perms: ["admin", "payments"]
            },
            {
                name: "HistoriaWyplat",
                path: "/withdrawal_history",
                perms: ["admin", "payments"]
            },
        ]
    },
]
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      incomes: [],
      currency_conversion: [],
      charges: [],
      summary: [],
      summary_by_system: [],
    }
  },
  getters: {
    charges(state) {
      return state.charges
    },
    incomes(state) {
      return state.incomes
    },
    currencyConversion(state) {
      return state.currency_conversion
    },
    summary(state) {
      return state.summary
    },
    summaryBySystem(state) {
      return state.summary_by_system
    },
  },
  mutations: {
    SET_CHARGES(state, { charges }) {
      for (let item in charges) {
        if (charges[item].length === 0) {
          delete charges[item]
        }
      }
      state.charges = { ...charges };
    },
    SET_INCOMES(state, { incomes }) {
      for (let item in incomes) {
        if (incomes[item].length === 0) {
          delete incomes[item]
        }
      }
      state.incomes = { ...incomes };
    },
    SET_CURRENCY_CONVERSION(state, { currency_conversion }) {
      for (let item in currency_conversion) {
        if (currency_conversion[item].length === 0) {
          delete currency_conversion[item]
        }
      }
      state.currency_conversion = { ...currency_conversion };
    },
    SET_SUMMARY(state, { summary }) {
      state.summary = { ...summary };
    },
    SET_SUMMARY_BY_SYSTEM(state, { summary_by_system }) {
      state.summary_by_system = { ...summary_by_system };
    }
  },
  actions: {
    fetchPaymentsSummary(context, { dateStart, dateEnd, payment_or_order_number, systemId }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      if (payment_or_order_number) {
        formData.append("payment_or_order_number", payment_or_order_number)
      } else {
        formData.append("date_start", dateStart);
        formData.append("date_end", dateEnd);
        formData.append("currency_conversion", 1);
        formData.append("system_id", systemId);
      }

      return new Promise((resolve, reject) => {
        axios.post(`/api/payments/transactions_summary`, formData)
          .then((res) => {
            // Obiekt odpowiedzi jest dzielony i wrzucany do poszczególnych elementów stanu.
            context.commit("SET_CHARGES", {
              charges: res.data.requests
            });
            context.commit("SET_SUMMARY", { summary: res.data.summary });
            context.commit("SET_SUMMARY_BY_SYSTEM", { summary_by_system: res.data.summary_by_system });
            context.commit("SET_CURRENCY_CONVERSION", { currency_conversion: res.data.currency_conversion });
            context.commit("SET_INCOMES", { incomes: res.data.donations });
            context.dispatch("toggleLoader", false, { root: true });
            resolve(true);
          }).catch((error) => {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error);
          })
      });
    }
  }
}
import axios from "@/axios";

export default {
    namespaced: true,
    state() {
        return {
            orders: [],
            summary: {},
            pages: 0,
        }
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        pages(state) {
            return state.pages
        },
        summary(state) {
            return state.summary
        },
    },
    mutations: {
        SET_ORDERS(state, { orders }) {
            state.orders = [...orders];
        },
        SET_SUMMARY(state, { totalOrders, totalCommission, totalCount }) {
            state.summary = {
                totalOrders,
                totalCommission,
                totalCount
            }
        },
        SET_PAGES(state, { pages }) {
            state.pages = pages
        }
    },
    actions: {
        setOrders(context, {from, to, order_id, page, checkout_type, payment_form, checkout_package_id}) {
            context.dispatch("toggleLoader", true, { root: true });

            return new Promise((resolve, reject) => {
                axios.get(`/api/Balance/getCheckoutOrders/from/${from}/to/${to}/limit/20/page/${page}/checkout_type/${checkout_type}/payment_form/${payment_form}/order_id/${order_id}/checkout_package_id/${checkout_package_id}`).then((res) => {
                    context.commit("SET_ORDERS", { orders: res.data.data.orders });
                    context.commit("SET_SUMMARY", {
                        totalOrders: res.data.data.summary.amount,
                        totalCommission: res.data.data.summary.commission,
                        totalCount: res.data.data.summary.orders
                    });
                    context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.data.count) / 20) });
                    context.dispatch("toggleLoader", false, { root: true });
                    resolve(true)
                }).catch((error) => {
                    context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
                    context.dispatch("toggleLoader", false, { root: true });
                    reject(error)
                })
            })
        }
    }
}
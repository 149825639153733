import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      ticketList: [],
    }
  },
  getters: {
    all(state) {
      return state.ticketList;
    },
    opened(state) {
      return state.ticketList.filter((ticket) => ticket.category === "open")
    },
    recentlyClosed(state) {
      return state.ticketList.filter((ticket) => ticket.category === "closed")
    },
    withUnfinishedJobs(state) {
      return state.ticketList.filter((ticket) => ticket.category === "withunfinishedjobs")
    },
  },
  mutations: {
    SET_TICKETS(state, payload) {
      state.ticketList = payload.ticketList;
    }
  },
  actions: {
    getTickets(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get('/api/support/getTickets').then(response => {

          const ticketsArray = [];
          if (response.data['result'] !== 'error') {
            for (const ticket in response.data) {
              ticketsArray.push(response.data[ticket])
            }
          }
          context.commit('SET_TICKETS', { ticketList: ticketsArray });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    findTickets(context, {
      title,
      content,
      from,
      to,
    }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("from", from);
        formData.append("to", to);
        formData.append("title", title);
        formData.append("content", content);
        axios.post(`/api/Support/getTickets`, formData).then(response => {
          const ticketsArray = [];
          for (const ticket in response.data) {
            ticketsArray.push(response.data[ticket])
          }
          context.commit('SET_TICKETS', { ticketList: ticketsArray });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    }
  },
}
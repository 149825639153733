<template>
  <div id="container" class="container">
    <div id="layout" class="layout px-3">
      <Header />
      <div class="row">
        <Aside />
        <div class="col-12 col-md-9">
          <div id="content">
            <Loader v-if="loader" />
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Aside from "./components/layout/Aside.vue";
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import Loader from "./components/base/Loader.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Aside,
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
    Loader,
  },
  provide() {
    return {
      app_mode: "shop",
    };
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      loader: "loaderState",
    }),
  },
  created() {
    document.title = `${this.$t("BiuroObslugiKlienta")} ${this.$service}`;
  },
};
</script>

<style lang="less">
@import "./styles/variables.less";

#content {
  @media @laptop {
    padding-left: 50px;
  }
}
#container {
  min-height: calc(100vh - 121px);
  padding-bottom: 30px;
  @media @laptop {
    min-height: calc(100vh - 55px);
  }
}
</style>
<template>
  <div class="page_heading">
    <div class="page_heading__sup d-none d-md-block">
      {{ $t(headingData.sup) }}
    </div>
    <div class="page_heading__content d-flex align-items-center">
      <div class="page_heading__icon mr-2">
        <i class="icon-bok_users"></i>
      </div>
      <div class="page_heading__text">
        <span>{{ $t(headingData.title) }}</span>
        <strong class="d-block">{{ $service }}</strong>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Heading",
  computed: {
    headingData() {
      if (this.$mode === "shop" || this.$mode === "shoprenter") {
        return {
          sup: "NajlepszeRozwiazaniaDoSprzedazyWInternecie",
          title: "BiuroObslugiKlienta",
        };
      } else {
        return {
          sup: "NajlepszeRozwiazaniaDoRezerwacjiWInternecie",
          title: "BiuroObslugiKlienta",
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/variables.less";

.page_heading {
  padding: 15px 0;
  @media @laptop {
    padding: 35px 0;
    border-top: 1px solid @background_border;
  }

  &__sup {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
  &__icon {
    font-size: 5rem;
    @media @laptop {
      font-size: 7rem;
    }
  }
  &__text {
    font-size: 2.5rem;
    line-height: 1;
    @media @laptop {
      font-size: 3.4rem;
    }
  }
}
</style>
<template>
  <div class="task_item p-3 d-sm-flex justify-content-between">
    <div
      class="task_item__content d-flex justify-content-between flex-grow-1 p-sm-0"
    >
      <div class="task_item__title mb-sm-0 pr-3">
        <strong class="d-block mb-1">{{ name }}</strong>
        <span>{{ ordertime }}</span>
      </div>
      <div
        class="task_item__details flex-shrink-0 d-flex flex-column justify-content-center align-items-end"
      >
        <div class="task_item__price mb-1">
          <strong class="pr-1">{{ $formatPrice(balance_info.job_price_actual, currency)}}</strong><span> {{ $t("netto") }}</span>
        </div>
        <div class="task_item__ticket" v-if="ticket > 0">
          <span>{{ $t("Komunikat") }}: </span
          ><router-link :to="`/support/ticket/${ticket}`"
            ><strong>#{{ ticket }}</strong></router-link
          >
        </div>
      </div>
    </div>
    <button
      class="task_item__link flex-shrink-0 d-flex mt-5 mt-sm-0 align-items-center mx-auto pl-sm-3 ml-sm-3"
      @click.prevent="openTaskDetailsButton"
    >
      <strong
        >{{ $t("Szczegoly") }}
        <i class="icon-angle_right d-none d-sm-inline"></i
      ></strong>
    </button>
    <Modal
      v-if="taskDetailsOpen"
      backgroundClose
      top
      right
      @modal-close="closeTaskDetails"
    >
      <div>
        <div class="payment_details__title mb-5">
          <strong
            >{{ $t("Szczegoly") }} "<span>{{ name }}</span
            >"</strong
          >
        </div>
        <div class="payment_details__section">
          <div
            v-if="progress"
            class="payment_details__content py-2 d-flex justify-content-between"
          >
            <span>{{ $t("ZaawansowaniePrac") }}</span>
            <strong v-if="parseInt(progress) === 1">{{
              $t("Ukonczone")
            }}</strong>
            <strong v-else>{{ progress * 100 }}%</strong>
          </div>
          <div
            class="payment_details__content py-2 d-flex justify-content-between"
          >
            <span>{{ $t("DataUtworzenia") }}</span>
            <strong>{{ ordertime }}</strong>
          </div>
          <div
            class="payment_details__content py-2 d-flex justify-content-between"
            v-if="starttime && starttime !== '0000-00-00 00:00:00'"
          >
            <span>{{ $t("RealizacjeRozpoczeto") }}</span>
            <strong>{{ starttime }}</strong>
          </div>
          <div
            class="payment_details__content py-2 d-flex justify-content-between"
            v-if="finishtime !== '0000-00-00 00:00:00'"
          >
            <span>{{ $t("RealizacjeZakonczono") }}</span>
            <strong>{{ finishtime }}</strong>
          </div>
          <div
            v-if="pricing_type !== 'current' && pricing_type !== 'gift'"
            class="payment_details__content py-2 d-flex justify-content-between"
          >
            <span>{{ $t("TypRozliczen") }}</span>
            <strong>{{ $t(type) }}</strong>
          </div>
          <div
            class="payment_details__content py-2 d-flex justify-content-between"
            v-if="balance_info.job_price_actual > 0"
          >
            <span>{{ $t("Oplata") }}</span>
            <strong>{{ $formatPrice(balance_info.job_price_actual, currency) }}</strong>
          </div>
          <div
            class="payment_details__content py-2"
            v-if="status === 'needs_approval' && $user.login !== 'support' && balance_info.sufficient"
          >
            <div
              class="d-flex flex-wrap mx-n2 mb-n2 mt-2 justify-content-between"
            >
              <button
                @click.prevent="acceptTask"
                class="btn --solid flex-grow-1 --primary mx-2 mb-2"
              >
                {{ $t("ZaakceptujZadanie") }}
              </button>
              <button
                @click.prevent="refuseTask"
                class="btn --solid --black flex-grow-1 mx-2 mb-2"
              >
                {{ $t("OdrzucZadanie") }}
              </button>
            </div>
          </div>
          <div v-else-if="status === 'needs_approval' && !balance_info.sufficient">
            <Alert type="info" label="Informacja" class="mt-3">
              {{ $t("NieMoznaZatwierdzicZadaniaKosztXDostepneXBrakX").format($formatPrice(balance_info.job_price_actual, currency), $formatPrice(balance_info.available_funds, currency), $formatPrice(-balance_info.job_price_missing_amount, currency)) }}
            </Alert>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatTaskPricingType } from "@/utils/utils";
export default {
  emits: ["task-list-update"],
  props: [
    "name",
    "person_assigned",
    "est_time",
    "used_time",
    "id",
    "ordertime",
    "starttime",
    "finishtime",
    "status",
    "pricing_type",
    "ticket",
    "progress",
    "amount",
    "approved_cost",
    "balance_info",
    "currency",
  ],
  data() {
    return {
      taskDetailsOpen: false,
    };
  },
  computed: {
    type() {
      return formatTaskPricingType(this.pricing_type);
    },
  },
  methods: {
    ...mapActions({
      approveTask: "balanceTasksConfirm/approveTask",
      rejectTask: "balanceTasksConfirm/rejectTask",
    }),
    acceptTask() {
      this.approveTask({ id: this.id }).then(() => {
        this.$emit("task-list-update");
        this.closeTaskDetails();
      });
    },
    refuseTask() {
      this.rejectTask({ id: this.id }).then(() => {
        this.$emit("task-list-update");
        this.closeTaskDetails();
      });
    },
    closeTaskDetails() {
      this.taskDetailsOpen = false;
    },
    openTaskDetailsButton() {
      this.taskDetailsOpen = true;
    },
  },
};
</script>

<style lang="less">
@import "../styles/variables.less";

.task_item {
  border: 1px solid @background_border;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  position: relative;
  &:hover {
    box-shadow: @box-shadow_hover;
    color: @background_color_text;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    strong {
      font-size: 1.5rem;
    }
    span {
      font-size: 1.3rem;
    }
  }
  &__price {
    font-size: 1.5rem;
    @media @tablet {
      text-align: right;
    }
  }
  &__ticket {
    font-size: 1.3rem;
    a {
      color: @color_primary;
      &:hover {
        color: @background_color_text;
      }
    }
  }
  &__link {
    font-size: 1.2rem;
    text-transform: uppercase;
    @media @tablet {
      border-left: 1px solid @background_border;
    }

    a {
      color: @color_primary;
      &:hover {
        color: @background_color_text;
      }
    }
  }
}
</style>
export default {
  meta: { breadCrumb: 'Faktury', breadCrumbLink: true },
  path: '/invoices',
  name: 'Invoices',
  component: () => import('@/views/invoices/Root.vue'),
  children: [
    {
      meta: { breadCrumb: 'Szczegoly' },
      path: 'details/:invoice_id/proforma/:proforma/idopayments/:idopayments',
      component: () => import('@/views/invoices/Details.vue'),
    },
    {
      path: '/invoices',
      component: () => import('@/views/invoices/Invoices.vue'),
    },
    {
      meta: { breadCrumb: 'Szczegoly' },
      path: 'group_details/:invoice_id/proforma/:proforma/group/:group',
      component: () => import('@/views/invoices/GroupDetails.vue'),
    },
  ]
};
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      clientData: {},
      shops: [
      ],
      pinPhone: "",
      contactData: {
        person: "",
        email: "",
        phone: "",
        code: "",
      },
      beneficiariesData: {
        beneficiaries: [
          {
            beneficiary_nationality: "",
            beneficiary_is_pep: "n",
            beneficiary_name: "",
            id: 0,
          }
        ],
        id: 1,
        additional_documents_link: "",
        additional_documents_id: "",
      },
      representativesData: {
        board_member: "y",
        representatives: [],
        files: [],
        fileId: 0,
        id: 0,
      },
      has_retail_sales: 'wholesale',
      has_terminal: 'n',
      has_moto_payments: 'n',
      additionalDocumentsData: {
        url: "",
        name: "",
        value: "",
      },
    }
  },
  getters: {
    has_retail_sales(state) {
      return state.has_retail_sales;
    },
    has_terminal(state) {
      return state.has_terminal;
    },
    has_moto_payments(state) {
      return state.has_moto_payments;
    },
    clientData(state) {
      return state.clientData;
    },
    pinPhone(state) {
      return state.pinPhone;
    },
    form_id(state) {
      return state.form_id;
    },
    kyc_required(state) {
      return state.kyc_required;
    },
    shops(state) {
      return state.shops;
    },
    categories(state) {
      return state.categories;
    },
    contactData(state) {
      return state.contactData;
    },
    beneficiaries(state) {
      return state.beneficiariesData.beneficiaries;
    },
    beneficiaryId(state) {
      return state.beneficiariesData.id;
    },
    nationalities(state) {
      return state.nationalities;
    },
    additional_documents_id(state) {
      return state.additional_documents_id;
    },
    additional_documents_link(state) {
      return state.additional_documents_link;
    },
    representativesMode(state) {
      return state.representativesData.board_member;
    },
    representativesList(state) {
      return state.representativesData.representatives;
    },
    representativesNumber(state) {
      return state.representativesData.representatives.length;
    },
    representativesId(state) {
      return state.representativesData.id
    },
    additionalDocumentsData(state) {
      return state.additionalDocumentsData;
    },
    representativesNames(state) {
      return state.representativesData.representatives.map((item) => {
        return {
          name: item.representative_name,
          id: item.representative_id,
          valid: true,
        };
      });
    },
  },
  mutations: {
    SET_CLIENT_DATA(state, payload) {
      state.clientData = { ...payload }
    },
    SET_FORM_ID(state, payload) {
      state.form_id = payload
    },
    SET_KYC_REQUIRED(state, payload) {
      state.kyc_required = payload
    },
    SET_NEW_DATA(state, payload) {
      state.newData = { ...payload }
    },
    SET_PIN_PHONE(state, { phone }) {
      state.pinPhone = phone;
    },
    SET_SHOPS_DATA(state, payload) {
      state.shops = [...payload]
    },
    ADD_SHOP(state, { shopData }) {
      for (const shop of state.shops) {
        if (shop.id == shopData.id) {
          return;
        }
      }
      state.shops.push({ ...shopData });
    },
    SET_CONTACT_DATA(state, payload) {
      state.contactData = { ...payload }
    },
    SET_BENEFICIARIES(state, payload) {
      state.beneficiariesData.beneficiaries = [...payload]
    },
    SET_HAS_TERMINAL(state, { has_terminal }) {
      state.has_terminal = has_terminal;
    },
    SET_HAS_RETAIL_SALES(state, { has_retail_sales }) {
      state.has_retail_sales = has_retail_sales;
    },
    SET_HAS_MOTO_PAYMENTS(state, { has_moto_payments }) {
      state.has_moto_payments = has_moto_payments;
    },
    INCREMENT_BENEFICIARY_ID(state) {
      state.beneficiariesData.id++
    },
    ADD_BENEFICIARY(state, { beneficiaryData }) {
      state.beneficiariesData.beneficiaries.push({
        ...beneficiaryData
      })
    },
    SAVE_BENEFICIARIES(state, { beneficiariesArray }) {
      state.beneficiariesData.beneficiaries = [...beneficiariesArray];
    },
    REMOVE_BENEFICIARY(state, { beneficiaryId }) {
      state.beneficiariesData.beneficiaries = state.beneficiariesData.beneficiaries.filter((person) => person.id !== beneficiaryId);
    },
    SET_ADDITIONAL_DOCUMENTS_ID(state, { document_id }) {
      state.additional_documents_id = document_id
    },
    SET_ADDITIONAL_DOCUMENTS_LINK(state, { document_link }) {
      state.additional_documents_link = document_link
    },
    INCREMENT_REPRESENTATIVES_ID(state) {
      state.representativesData.id++;
    },
    ADD_REPRESENTATIVE(state, { representativeData }) {
      state.representativesData.representatives.push({
        ...representativeData
      })
    },
    SAVE_REPRESENTATIVES(state, { representativesArray }) {
      state.representativesData.representatives = [...representativesArray];
    },
    REMOVE_REPRESENTATIVE(state, { representativeId }) {
      state.representativesData.representatives = state.representativesData.representatives.filter((person) => person.representative_id !== representativeId);
    },
    CHANGE_REPRESENTATIVE(state, { representativeId, input_value, url }) {
      state.representativesData.representatives = state.representativesData.representatives.map((person) => {
        if (person.representative_id === representativeId) {
          person.id_iframe_input_value = input_value;
          person.id_iframe_url = url;
        }
        return person;
      });
    },
    SET_REPRESENTATIVES_MODE(state, { mode }) {
      state.representativesData.board_member = mode;
    },
    INCREMENT_REPRESENTATIVE_ID(state) {
      state.representativesData.id++;
    },
    SET_ADDITIONAL_DOCUMENTS(state, { additionalDocumentsData }) {
      state.additionalDocumentsData = {
        ...additionalDocumentsData
      }
    }
  },
  actions: {
    fetchData(context, { shops, }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/account/get").then((res) => {
          context.commit("SET_FORM_ID", res.data.last_kyc_data.form_id)
          context.commit("SET_KYC_REQUIRED", res.data.kyc_required)

          context.commit("SET_CLIENT_DATA", {
            agreement: res.data.client_data.agreement_signed,
            id: res.data.client_data.id,
            company_name: res.data.client_data.company_name,
            address: res.data.client_data.address,
            zipcode: res.data.client_data.zipcode,
            city: res.data.client_data.city,
            country: res.data.client_data.country,
            nip: res.data.client_data.nip,
            currency: res.data.client_data.billing_currency,
            pin: "XXXXXX",
          });

          context.commit("SET_CONTACT_DATA", {
            person: res.data.client_data.manager_name,
            email: res.data.client_data.manager_email,
            invoice_email: res.data.client_data.invoice_email,
            phone: res.data.client_data.phone?.split(/\s+/)[1],
            code: res.data.client_data.phone?.split(/\s+/)[0],
          });

          context.commit("SET_PIN_PHONE", { phone: res.data.client_data.phone })

          const shops_array = [];
          for (const shop of shops) {
            shops_array.push({
              name: shop.name,
              id: shop.id,
              category: res.data.last_kyc_data.survey?.profile?.[shop.id] || "",
              month_turnover: res.data.last_kyc_data.survey?.month_turnover?.[shop.id] || "",
              average_value: res.data.last_kyc_data.survey?.average_value?.[shop.id] || "",
              rooms: res.data.last_kyc_data.survey?.rooms || "",
            })
            context.commit("SET_SHOPS_DATA", shops_array);
          }

          context.commit("SET_HAS_RETAIL_SALES", { has_retail_sales: res.data.last_kyc_data.has_retail_sales });
          context.commit("SET_HAS_TERMINAL", { has_terminal: res.data.last_kyc_data.has_terminal });
          context.commit("SET_HAS_MOTO_PAYMENTS", { has_moto_payments: res.data.last_kyc_data.has_moto_payments });

          const beneficiariesArray = [];
          for (const person of res.data.last_kyc_data.beneficiary) {
            context.commit("INCREMENT_BENEFICIARY_ID");
            beneficiariesArray.push({
              nationality: person.nationality,
              is_pep: person.is_pep,
              name: person.name,
              id: context.getters.beneficiaryId,
            })
          }
          if (beneficiariesArray.length) {
            context.commit("SAVE_BENEFICIARIES", { beneficiariesArray });
          }

          context.commit("SET_REPRESENTATIVES_MODE", { mode: res.data.last_kyc_data.board_member ? res.data.last_kyc_data.board_member : "y" });
          const representativesArray = [];
          for (const person of res.data.last_kyc_data.representatives) {
            context.commit("INCREMENT_REPRESENTATIVES_ID");
            const timestamp = new Date().getTime();
            const id_iframe_input_value = `representative_document__${context.getters.representativesId}_${timestamp}`;

            representativesArray.push({
              representative_id: context.getters.representativesId,
              representative_name: person.name,
              representative_nationality: person.nationality || "1143020003",
              representative_birth_country: person.birth_country || "1143020003",
              representative_birth_date: person.birth_date || "",
              representative_pesel: person.pesel,
              representative_document_type: person.document_type || "dowód osobisty",
              representative_document_number: person.document_number,
              representative_document_date_ymd: person.document_date,
              representative_document_without_date: !!person.document_without_date,
              id_iframe_url: "",
              id_iframe_input_name: `representative_document_image_${context.getters.representativesId}`,
              id_iframe_input_value,
              provided: false,
            });
          }
          if (representativesArray.length) {
            context.commit("SAVE_REPRESENTATIVES", { representativesArray });
            context.dispatch("fetchRepresentativesIdIframesHelper").then(() => {
              context.dispatch("toggleLoader", false, { root: true });
              context.dispatch("fetchRepresentativesAdditionalDocumentsIframe").then(() => {
                resolve(true);
              })
            }).catch((error) => {
              context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
              resolve(true);
              context.dispatch("toggleLoader", false, { root: true });
            });
          } else {
            context.dispatch("toggleLoader", false, { root: true });
            context.dispatch("addRepresentative").then(() => {
              context.dispatch("fetchRepresentativesAdditionalDocumentsIframe").then(() => {
                resolve(true);
              })
            });
          }

          resolve(true);
          context.dispatch("toggleLoader", false, { root: true });
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    resetPin(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/documents/pin_reset").then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve();
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          reject(error);
        })
      })
    },
    setNewData(context, payload) {
      context.commit("SET_NEW_DATA", payload);
    },
    addBeneficiary(context) {
      context.commit("INCREMENT_BENEFICIARY_ID");
      context.commit("ADD_BENEFICIARY", {
        beneficiaryData: {
          beneficiary_nationality: "",
          beneficiary_is_pep: "n",
          beneficiary_name: "",
          id: context.getters.beneficiaryId,
        }
      });
    },
    removeBeneficiary(context, { beneficiaryId }) {
      context.commit("REMOVE_BENEFICIARY", { beneficiaryId });
    },
    fetchRepresentativesAdditionalDocumentsIframe(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        const timestamp = new Date().getTime();
        const additional_documents_iframe_input_value = `${context.getters.form_id}_proxy_${timestamp}`;
        axios.get(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${additional_documents_iframe_input_value}`).then((res) => {
          context.commit("SET_ADDITIONAL_DOCUMENTS", {
            additionalDocumentsData: {
              url: res.data.iframe_url,
              name: "additional_documents[]",
              value: res.data.input_value,
            }
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    fetchRepresentativesIdIframesHelper(context) {
      return new Promise((resolve, reject) => {
        const promisesArray = [];
        for (const person of context.getters.representativesList) {
          promisesArray.push(new Promise((resolveSub, rejectSub) => {
            axios.get(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${person.id_iframe_input_value}`).then((res) => {
              context.commit("CHANGE_REPRESENTATIVE", {
                representativeId: person.representative_id,
                input_value: res.data.input_value,
                url: res.data.iframe_url,
              });
              resolveSub(true)
            }).catch((error) => {
              rejectSub(error);
            });
          }));
        }
        Promise.all(promisesArray).then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    addRepresentative(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        context.commit("INCREMENT_REPRESENTATIVES_ID");
        const timestamp = new Date().getTime();
        const id_iframe_input_value = `representative_document__${context.getters.representativesId}_${timestamp}`;
        axios.post(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${id_iframe_input_value}`).then((res) => {
          context.commit("ADD_REPRESENTATIVE", {
            representativeData: {
              representative_id: context.getters.representativesId,
              representative_name: "",
              representative_nationality: "1143020003",
              representative_birth_country: "1143020003",
              representative_birth_date: "",
              representative_pesel: "",
              representative_document_type: "dowód osobisty",
              representative_document_number: "",
              representative_document_date_ymd: "",
              representative_document_without_date: false,
              id_iframe_url: res.data.iframe_url,
              id_iframe_input_name: `representative_document_image_${context.getters.representativesId}`,
              id_iframe_input_value,
              provided: false,
            }
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    saveRepresentatives(context, { representativesArray }) {
      context.commit("SAVE_REPRESENTATIVES", { representativesArray });
    },
    removeRepresentative(context, { representativeId }) {
      context.commit("REMOVE_REPRESENTATIVE", { representativeId })
    },
    setRepresentativesMode(context, { mode }) {
      context.commit("SET_REPRESENTATIVES_MODE", { mode })
    },
    submitForm(context, { formData, kycaml = false }) {
      context.dispatch("toggleLoader", true, { root: true });
      if (kycaml) {
        formData.append("form_id", context.getters.form_id);
      }
      return new Promise((resolve, reject) => {
        axios.post("/api/account/set", formData).then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          if (kycaml) {
            document.querySelectorAll('.id_iframe_message').forEach((elem) => {
              elem.contentWindow.postMessage({event_id: 'send'}, "*")
            })
          }
          resolve()
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })

    }
  }
}
import {formatPaymentName} from "@/utils/utils";

export default {
  meta: { breadCrumb: 'Saldo' },
  path: '/balance',
  name: 'Balance',
  component: () => import('@/views/balance/Balance.vue'),
  children: [
    {
      meta: { breadCrumb: 'WplataSrodkow' },
      path: 'payments',
      component: () => import('@/views/balance/Payments.vue'),
    },
    {
      meta: { breadCrumb: 'WplataSrodkow' },
      path: 'payments_archive',
      component: () => import('@/views/balance/PaymentsArchive.vue'),
    },
    {
      meta: { breadCrumb: 'HistoriaWydatkowIZasilen' },
      path: 'history',
      component: () => import('@/views/balance/History.vue'),
    },
    {
      meta: { breadCrumb: route => formatPaymentName(`${route.query.system}`) },
      path: 'history/details',
      props: route => ({ ...route.query }),
      component: () => import('@/views/balance/HistoryDetails.vue'),
    },
    {
      meta: { breadCrumb: 'ZadaniaDoZatwierdzenia' },
      path: 'confirm_tasks',
      component: () => import('@/views/balance/ConfirmTasks.vue'),
    },
    {
      meta: { breadCrumb: 'RozliczeniaUslugDodatkowych' },
      path: 'additional_services',
      component: () => import('@/views/balance/AdditionalServices.vue'),
      children: [
        {
          meta: { breadCrumb: 'SMS' },
          path: 'sms',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/Sms.vue'),
        },
        {
          meta: { breadCrumb: 'SMS' },
          path: 'sms_dates',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/SmsDates.vue'),
        },
        {
          meta: { breadCrumb: 'SMS' },
          path: 'sms_details',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/SmsDetails.vue'),
        },
        {
          meta: { breadCrumb: 'Freshmail' },
          path: 'freshmail',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/Freshmail.vue'),
        },
        {
          meta: { breadCrumb: 'Freshmail' },
          path: 'freshmail_dates',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/FreshmailDates.vue'),
        },
        {
          meta: { breadCrumb: 'Freshmail' },
          path: 'freshmail_details',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/FreshmailDetails.vue'),
        },
        {
          meta: { breadCrumb: 'IdoSell Broker' },
          path: 'idosell_broker',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/IdoSellBroker.vue'),
        },
        {
          meta: { breadCrumb: 'IdoPay' },
          path: 'idopay',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/IdoPay.vue'),
        },
        {
          meta: { breadCrumb: 'Checkout 2.0' },
          path: 'checkout',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/Checkout.vue'),
        },
        {
          meta: { breadCrumb: 'Express Checkout' },
          path: 'express-checkout-service',
          props: route => ({ ...route.query }),
          component: () => import('@/views/balance/additional_services/ExpressCheckoutService.vue'),
        },
      ]
    },
  ]
}
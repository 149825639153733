import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      ticketId: null,
      instruction: "",
      iframeUrl: ""
    }
  },
  getters: {
    ticketId(state) {
      return state.ticketId
    },
    instruction(state) {
      return state.instruction
    },
    iframeUrl(state) {
      return state.iframeUrl
    },
  },
  mutations: {
    SET_TICKET_NUMBER(state, { ticketId }) {
      state.ticketId = ticketId;
    },
    SET_IFRAME_URL(state, { iframeUrl }) {
      state.iframeUrl = iframeUrl
    },
    SET_TICKET_INSTRUCTION(state, { instruction }) {
      state.instruction = instruction;
    },
  },
  actions: {
    postTicket(context, { formData }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.post('/api/support/addTicket', formData).then((res) => {
          context.commit("SET_TICKET_NUMBER", { ticketId: res.data.success });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(res);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        });
      })
    },
    fetchTicketDetails(context, { topicId }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Support/getHelpline/id/${topicId}`).then((res) => {
          context.commit("SET_IFRAME_URL", { iframeUrl: res.data.url });
          context.commit("SET_TICKET_INSTRUCTION", { instruction: res.data.topic.instruction });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        });
      })
    }
  }
}
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      freshmailDetails: [
        {
          recipient: "martek.grechuta@onet.pl",
          date: "2019-05-09 15:16:27",
          cost: "0.30 zł",
          sender: "bok@esena.pl",
          sent: "y",
          text: "Przykładowa treść wysłanej wiadomości"
        },
        {
          recipient: "martek.grechuta@onet.pl",
          date: "2019-05-09 15:16:27",
          cost: "0.30 zł",
          sender: "bok@esena.pl",
          sent: "y",
          text: "Przykładowa treść wysłanej wiadomości"
        },
        {
          recipient: "martek.grechuta@onet.pl",
          date: "2019-05-09 15:16:27",
          cost: "0.30 zł",
          sender: "bok@esena.pl",
          sent: "y",
          text: "Przykładowa treść wysłanej wiadomości"
        },
      ]
    }
  },
  getters: {
    all(state) {
      return state.freshmailDetails;
    },
  },
  mutations: {
    SET_FRESHMAIL_DETAILS(state, { freshmailDetails }) {
      state.freshmailDetails = [...freshmailDetails];
    }
  },
  actions: {
    fetchFreshmailDetails(context, { dateStart, dateEnd }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getMails/from/${dateStart}/to/${dateEnd}/details/true`
          // , {
          //   from: dateStart,
          //   to: dateEnd,
          //   currency,
          //   group,
          //   page,
          //   limit: 20,
          // }
        ).then((res) => {
          context.commit("SET_FRESHMAIL_DETAILS", { freshmailDetails: res.data.mails });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    }
  }
}
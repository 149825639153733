<template>
  <div class="form__select">
    <select
      class="form__input --focused"
      :class="className"
      :id="id"
      :name="name"
      @change="change"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :selected="selectedOption(option)"
      >
        {{ transformOptionLabel(option.label) }}
      </option>
    </select>
    <label v-if="label" :for="id" class="form__label">{{
      transformedLabel
    }}</label>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "value-change"],
  name: "InputSelect",
  props: {
    id: {
      type: String,
      required: true,
    },
    options: Array,
    modelValue: [String, Number],
    name: {
      type: String,
      required: true,
    },
    className: String,
    label: {
      type: String,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    transformedLabel() {
      if (this.$te(this.label)) {
        return this.$t(this.label);
      } else {
        return this.label;
      }
    },
  },
  methods: {
    selectedOption(option) {
      if (this.modelValue) {
        return option.value === this.modelValue;
      }
      return false;
    },
    change(e) {
      const selectedOption = e.target.value;
      const option = this.options.find((option) => {
        return selectedOption === option.value;
      });
      this.$emit("update:modelValue", option.value);
      this.$emit("value-change", option.value);
    },
    transformOptionLabel(label) {
      if (this.$te(label)) {
        return this.$t(label);
      } else {
        return label;
      }
    },
  },
};
</script>
<template>
  <div class="table">
    <div class="table__content">
      <div class="table__row --header">
        <div
          v-for="(header, index) in tableHeader"
          :key="header.id ? header.id : index"
          class="table__cell p-4 --header"
        >
          {{ $t(header) }}
        </div>
      </div>
      <div
        class="table__row"
        v-for="(row, index) in tableContent"
        :key="row.id ? row.id : index"
      >
        <div
          class="table__cell p-4"
          v-for="(cell, index) in row"
          :key="cell.id ? cell.id : index"
        >
          <span v-if="typeof cell === 'string'">{{ $t(cell) }}</span>
          <router-link
            v-else
            v-for="(data, index) in cell"
            :key="data.id ? data.id : index"
            :to="data.to"
            >{{ $t(data.name) }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Table",
  props: {
    tableHeader: Array,
    tableContent: Array,
  },
};
</script>
<template>
  <div class="spinner_wrapper">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style lang="less">
@import "../../styles/variables.less";

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background: @background;
  z-index: 6;
  border-radius: 50%;
  box-shadow: @box-shadow_hover;
  @media @tablet {
    left: calc(50% + 190px);
  }
  &_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
}
.spinner div {
  transform-origin: 40px 40px;
  border-radius: 10px;
  animation: spinner 0.8s linear infinite;
}
.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 20px;
  left: 38px;
  width: 4px;
  height: 10px;
  border-radius: 100px;
  background: @color_info;
}
.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.7s;
}
.spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.6s;
}
.spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.4s;
}
.spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.3s;
}
.spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.2s;
}
.spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.1s;
}
.spinner div:nth-child(8) {
  transform: rotate(315deg);
}
@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
import axios from "@/axios";


export default function fetchConfig() {
  return new Promise((resolve, reject) => {
    axios.get("/api/Client/getConfig").then((res) => {
      resolve({ ...res.data });
    }).catch((error) => {
      reject(error);
    })
  })
}
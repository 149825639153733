export default {
  meta: { breadCrumb: 'Wplaty' },
  path: '/payments',
  name: 'Wplaty',
  component: () => import('@/views/payments/Payments.vue'),
  children: [
    {
      meta: { breadCrumb: 'ListaWplat' },
      path: 'list',
      name: 'ListaWplat',
      component: () => import('@/views/payments/List.vue'),
    },
    {
      meta: { breadCrumb: 'WyplataSrodkow' },
      path: 'withdrawal',
      name: 'ListaWyplat',
      children: [
        {
          meta: { breadCrumb: 'IdoPay' },
          path: 'idopay',
          props: route => ({ ...route.query }),
          component: () => import('@/views/payments/withdrawal/WithdrawalIdoPay.vue'),
        },
        {
          meta: { breadCrumb: 'Pobrania' },
          path: 'iai',
          props: route => ({ ...route.query }),
          component: () => import('@/views/payments/withdrawal/Withdrawal.vue'),
        },
      ],
    },
    {
      meta: { breadCrumb: 'HistoriaWyplat' },
      path: 'withdrawal_history',
      name: 'HistoriaWyplat',
      component: () => import('@/views/payments/WithdrawalHistory.vue'),
    },
    {
      meta: { breadCrumb: 'HistoriaWyplatProwizji' },
      path: 'withdrawal_history/invoice/:invoice/group/:group',
      name: 'HistoriaWyplatProwizji',
      component: () => import('@/views/payments/WithdrawalHistory.vue'),
    },
    {
      meta: { breadCrumb: 'UstawieniaWyplat' },
      path: 'withdrawal_settings',
      name: 'UstawieniaWyplat',
      component: () => import('@/views/payments/WithdrawalSettings.vue'),
    },
    {
      meta: { breadCrumb: 'Szczegoly' },
      path: 'details',
      name: 'Szczegoly',
      props: route => ({...route.query}),
      component: () => import('@/views/payments/Details.vue'),
    },
  ]
}
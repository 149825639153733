/* eslint-disable no-useless-escape */
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      data: {
        data: {},
      },
      iframeUrl: ""
    }
  },
  getters: {
    id(state) {
      return state.data.id;
    },
    title(state) {
      return state.data.title;
    },
    person_login(state) {
      return state.data.assigned?.login;
    },
    person_message(state) {
      return state.data.assigned?.available?.personMessage;
    },
    can_switch_assigned(state) {
      return state.data.canSwitchAssigned;
    },
    person_name(state) {
      return state.data.assigned?.name;
    },
    messages(state) {
      return state.data.data;
    },
    assigned_tasks(state) {
      let tempArr = [];
      if (state.data.jobs && Object.keys(state.data.jobs).length) {
        for (let job in state.data.jobs) {
          tempArr.push(state.data.jobs[job]);
        }
      } else if (state.data.jobs) {
        tempArr = [...state.data.jobs]
      }
      return tempArr;
    },
    rate_ticket(state) {
      return state.data.notification === "y";
    },
    response_enabled(state) {
      return state.data.canReply;
    },
    iframeUrl(state) {
      return state.iframeUrl;
    },
  },
  mutations: {
    SET_DATA(state, { ticketData }) {
      const dataArr = [];
      for (const msg in ticketData.data) {
        dataArr.push(ticketData.data[msg])
      }
      state.data = { ...ticketData, data: dataArr };
    },
    SET_IFRAME_LINK(state, { url }) {
      state.iframeUrl = url;
    },
    RESET_IFRAME_LINK(state) {
      state.iframeUrl = "";
    }
  },
  actions: {
    fetchData(context, { id, searching }) {
      context.dispatch("toggleLoader", true, { root: true });
      context.commit("RESET_IFRAME_LINK");
      return new Promise((resolve, reject) => {
        axios.get(`/api/Support/getTicket/id/${id}/`).then((res) => {
          context.commit("SET_DATA", { ticketData: res.data })
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          if (searching && error.response?.headers["error-message"]) {
            context.dispatch("toggleLoader", false, { root: true });
            reject(error.response?.headers["error-message"]);
          } else {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error);
          }
        })
      })
    },
    fetchIframeLink(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/support/getHelpline/id/direct/person/${context.getters.person_login}_${context.getters.id}/`).then((res) => {
          context.commit("SET_IFRAME_LINK", { url: res.data.url })
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    postResponse(context, { id, formData }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.post(`/api/Support/addReply/id/${id}`, formData).then((res) => {
          context.dispatch("fetchData", { id });
          resolve(res.data);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    closeTicketByClient(context, { id }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("ticket_id", id);
      return new Promise((resolve, reject) => {
        axios.post(`/api/support/closeTicketByClient`, formData).then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    closeTicket(context, { id, satisfaction, comment }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("ticket", id);
      formData.append("satisfaction", satisfaction);
      formData.append("comment", comment);
      return new Promise((resolve, reject) => {
        axios.post(`/api/support/closeTicket`, formData).then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    rateHelpline(context, { recording_id, satisfaction, comment }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("recording_id", recording_id);
      formData.append("satisfaction", satisfaction);
      formData.append("comment", comment);
      return new Promise((resolve, reject) => {
        axios.post(`/api/support/rateHelpline`, formData).then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    switchAssigned(context, { id }) {
      context.dispatch("toggleLoader", true, { root: true });
      //context.commit("RESET_IFRAME_LINK");
      return new Promise((resolve, reject) => {
        axios.get(`/api/Support/changePerson/id/${id}/`).then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          if (error.response?.headers["error-message"]) {
            context.dispatch("toggleLoader", false, { root: true });
            reject(error.response?.headers["error-message"]);
          } else {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error);
          }
        })
      })
    }
  }
}
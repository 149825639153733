export default {
  path: '/support',
  meta: { breadCrumb: 'CentrumSupportu', breadCrumbLink: true },
  component: () => import('@/views/support/Support.vue'),
  children: [
    {
      path: 'ticket/:ticket_id',
      name: 'Ticket',
      meta: { breadCrumb: 'Komunikat' },
      component: () => import('@/views/support/Ticket.vue'),
    },
    {
      path: '',
      name: 'TicketList',
      component: () => import('@/views/support/TicketList.vue'),
    },
    {
      path: 'contact',
      name: 'Contact',
      meta: { breadCrumb: 'Kontakt' },
      component: () => import('@/views/support/Contact.vue'),
    },
    {
      path: 'contact_form/:topic_id',
      name: 'ContactForm',
      meta: { breadCrumb: route => `${route.query.title}` },
      component: () => import('@/views/support/ContactForm.vue'),
      children: [
        {
          path: '',
          name: 'SupportIframe',
          component: () => import('@/views/support/Iframe.vue'),
        },
        {
          path: 'new_ticket',
          name: 'NewTicket',
          component: () => import('@/views/support/NewTicket.vue'),
        }
      ]
    },
  ]
}
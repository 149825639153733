import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      details: {
        type: "",
        addedBy: "",
        additionDate: "",
        status: "",
        note: "",
        acceptedBy: "",
        signedByAssignee: "",
        ticketId: "",
        documentContent: "",
        documentMimeType: "",
        id: "",
        ipAddress: "",
        formId: "",
      },
      additionalDocumentsData: {
        url: "",
        name: "",
        value: "",
      },
    }
  },
  getters: {
    id(state) {
      return state.details.id;
    },
    type(state) {
      return state.details.type;
    },
    addedBy(state) {
      return state.details.addedBy;
    },
    additionDate(state) {
      return state.details.additionDate;
    },
    status(state) {
      return state.details.status;
    },
    note(state) {
      return state.details.note;
    },
    ipAddress(state) {
      return state.details.ipAddress;
    },
    formId(state) {
      return state.details.formId;
    },
    acceptedBy(state) {
      return state.details.acceptedBy;
    },
    signedByAssignee(state) {
      return state.details.signedByAssignee;
    },
    ticketId(state) {
      return state.details.ticketId;
    },
    documentContent(state) {
      return state.details.documentContent;
    },
    documentMimeType(state) {
      return state.details.documentMimeType;
    },
    additionalDocumentsData(state) {
      return state.additionalDocumentsData;
    }
  },
  mutations: {
    SET_DETAILS(state, { details }) {
      state.details = { ...details };
    },
    CLEAR_DETAILS(state) {
      state.details = {};
    },
    UPDATE_DETAILS(state, { details }) {
      for (let name in details) {
        if (state.details[name] !== details[name]) {
          state.details[name] = details[name];
        }
      }
    },
    SET_ADDITIONAL_DOCUMENTS(state, { additionalDocumentsData }) {
      state.additionalDocumentsData = {
        ...additionalDocumentsData
      }
    }
  },
  actions: {
    fetchDetails(context, { id }) {
      context.dispatch("toggleLoader", true, { root: true });
      if (id !== context.getters.id) {
        context.commit("CLEAR_DETAILS");
      }
      return new Promise((resolve, reject) => {
        axios.get(`/api/documents/get/id/${id}`).then((res) => {
          if (id !== context.getters.id) {
            context.commit("SET_DETAILS", { details: { ...res.data, id } });
          } else {
            context.commit("UPDATE_DETAILS", { details: { ...res.data, id } });
          }
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    fetchIframeLink(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        const timestamp = new Date().getTime();
        const additional_documents_iframe_input_value = `${context.getters.formId}_proxy_${timestamp}`;
        axios.get(`/api/documents/file_holder/form_id/${context.getters.formId}/input_id/${additional_documents_iframe_input_value}`).then((res) => {
          context.commit("SET_ADDITIONAL_DOCUMENTS", {
            additionalDocumentsData: {
              url: res.data.iframe_url,
              value: additional_documents_iframe_input_value,
              name: "additional_documents",
            }
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    sendAdditionalDocuments(context, { formData }) {
      context.dispatch("toggleLoader", true, { root: true });
      formData.append("document_id", context.getters.id);
      return new Promise((resolve, reject) => {
        axios.post("/api/documents/add_file_to_document", formData).then((res) => {
          context.dispatch("toggleLoader", false, { root: true });
          document.querySelectorAll('.id_iframe_message').forEach((elem) => {
            elem.contentWindow.postMessage({ event_id: 'send' }, "*")
          })
          if (res.data.result === "error") {
            reject({ error: res.data.error });
          }
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    deleteDocument(context, { id }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.post(`/api/documents/delete/id/${id}`).then(() => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
  }
}
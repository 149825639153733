<template>
  <div id="nav" class="aside col-md-3" :class="menuClass">
    <div @click.prevent="menuToggle" class="aside__backdrop"></div>
    <button
      class="mobile_menu_toggler d-md-none"
      :class="{ menu_open: isMenuOpen }"
      @click.prevent="menuToggle"
    >
      <i class="icon-hamburger"></i>
    </button>
    <ul class="aside__menu">
      <li
        v-for="item in menuItems"
        :key="item.name"
        v-show="userHasPermission(item.perms)"
        class="aside__menu_element"
        :class="{
          '--expand': item.children,
          '--expanded': openedItems[item.name],
        }"
      >
        <router-link
          v-if="!item.children"
          class="aside__menu_link"
          :to="item.path"
          ><span class="d-none d-md-inline-block"
            ><i :class="'icon-' + item.icon"></i></span
          ><span class="d-block d-md-inline" @click="closeMenu">{{
            $t(item.name)
          }}</span></router-link
        >
        <span
          v-else
          class="aside__menu_link"
          @click="collapseItem(item.name, item)"
          ><span class="d-none d-md-inline-block"
            ><i :class="'icon-' + item.icon"></i></span
          >{{ $t(item.name) }}</span
        >
        <ul class="aside__submenu" v-if="openedItems[item.name]">
          <li
            class="aside__menu_header d-md-none"
            @click="collapseItem(item.name, item)"
          >
            <span>{{ $t(item.name) }}</span>
          </li>
          <li
            class="aside__menu_element"
            v-for="subitem in item.children"
            v-show="userHasPermission(subitem.perms)"
            :key="subitem.name"
            :class="{
              '--expand': subitem.children,
              '--expanded': openedItems[subitem.name],
            }"
          >
            <router-link
              v-if="!subitem.children"
              class="aside__menu_link"
              :to="item.path + subitem.path"
              ><span class="d-block d-md-inline" @click="closeMenu">{{
                $t(subitem.name)
              }}</span></router-link
            >
            <span
              v-else
              @click="collapseItem(subitem.name, subitem)"
              class="aside__menu_link d-block"
              >{{ $t(subitem.name) }}</span
            >
            <ul class="aside__submenu" v-if="openedItems[subitem.name]">
              <li
                class="aside__menu_header d-md-none"
                @click="collapseItem(subitem.name, subitem)"
              >
                <span>{{ $t(subitem.name) }}</span>
              </li>
              <li
                class="aside__menu_element"
                :class="{ '--expand': subsubitem.children }"
                v-for="subsubitem in subitem.children"
                :key="subsubitem.name"
              >
                <router-link
                  v-if="!subsubitem.children"
                  class="aside__menu_link"
                  :to="item.path + subitem.path + subsubitem.path"
                  ><span class="d-block d-md-inline" @click="closeMenu">{{
                    $t(subsubitem.name)
                  }}</span></router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="d-flex d-md-none flex-column additional_menu">
        <a
          href="#logout"
          @click.prevent="logoutUser({ lang: $lang, mode: $mode })"
          >{{ $t("Wyloguj") }} {{$user.login}}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import shopArray from "@/config/shopMenu.js";
import bookingArray from "@/config/bookingMenu.js";
import shoprenterArray from "@/config/shoprenterMenu.js";
import { mapActions } from "vuex";
import {hasPermissions} from "@/utils/utils";

export default {
  props: {
    className: String,
  },
  data() {
    return {
      openedItems: {},
    };
  },
  computed: {
    menuClass() {
      if (this.$store.getters.menuState) {
        return "--menu_open";
      }
      return "";
    },
    isMenuOpen() {
      return this.$store.getters.menuState;
    },
    menuItems() {
      if (this.$mode === "shop") {
        return shopArray;
      } else if (this.$mode === "shoprenter") {
        return shoprenterArray;
      } else {
        return bookingArray;
      }
    },
  },
  methods: {
    ...mapActions({
      logoutUser: "logoutUser",
    }),
    collapseItem(name, item) {
      if (item.children != null) {
        this.openedItems[name] = !this.openedItems[name];
        this.$forceUpdate();
      }
    },
    closeMenu() {
      this.$store.dispatch("toggleMenu", false);
    },
    menuToggle() {
      this.$store.dispatch("toggleMenu");
    },
    userHasPermission(requiredPerms) {
      return hasPermissions(requiredPerms, this.$user.perms);
    }
  },
};
</script>

<style lang="less">
@import "../../styles/variables.less";

@media @laptop {
  .aside {
    position: relative;
    padding: 35px 15px 20px;
    align-self: flex-start;
    position: sticky !important;
    top: 0;
    &::before {
      background: @background_alternate;
      content: "";
      top: 0;
      bottom: 0;
      right: -15px;
      width: 100vw;
      position: absolute;
    }
    &__menu {
      position: relative;
      list-style-type: none;
      &_element {
        margin-bottom: 1.5rem;
        &.--expand {
          position: relative;
          & > span::after {
            position: absolute;
            content: "\E800";
            font-family: "fontello";
            font-size: 1rem;
            right: 0;
            top: 3px;
            transform: rotate(90deg);
            transition: transform 0.2s ease;
          }
          &.--expanded {
            & > span::after {
              transform: rotate(-90deg);
            }
          }
        }
      }
      &_link {
        cursor: pointer;
        display: block;
        color: @background_color_text;
        transition: color 0.2s ease;
        &.router-link-active,
        &:hover {
          color: @background_color_link;
        }
        span {
          width: 20px;
          margin-right: 10px;
          i {
            color: @background_color_grey;
            font-size: 1.6rem;
            vertical-align: middle;
          }
        }
      }
    }

    &__submenu {
      list-style: none;
      padding: 15px 0 0px 30px;

      &_element {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }

      &_link {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.--current {
          font-weight: bold;
        }
      }

      & & {
        padding: 0px 0 0px 15px;
        margin-top: 15px;
        border-left: 1px solid @background_alternate_border;
      }
    }
  }
}
@media (max-width: 979px) {
  .aside.--menu_open {
    .aside__menu {
      right: 0;
    }
    .aside__backdrop {
      display: block;
    }

    .menu__toggler {
      position: fixed;
      right: calc(70% - 60px);
      @media (max-width: 757px) {
        right: calc(100% - 65px);
      }
    }
  }

  .aside {
    &__backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background: #ffffff80;
      backdrop-filter: blur(4px);
      animation: fadeInDown 0.3s;
      display: none;
    }
    &__menu {
      background: #fff;
      display: block;
      padding: 0 0 60px !important;
      position: fixed !important;
      font-size: 1em;
      z-index: 1000;
      top: 0;
      bottom: 0px;
      overflow: auto;
      max-width: calc(70% - 60px);
      right: -100%;
      width: 100%;
      transition: right 0.3s ease;
      z-index: 999;
      box-shadow: 0 0 10px 0 #0000006e;
      @media (max-width: 757px) {
        max-width: calc(100% - 60px);
      }
      &_element {
        &.--expand {
          position: relative;
          & > span::after {
            position: absolute;
            content: "\E800";
            font-family: "fontello";
            font-size: 1rem;
            right: 15px;
            top: 10px;
          }
        }
      }
      &_header {
        position: relative;
        padding: 15px 30px;
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        &::before {
          content: "\E800";
          font-family: "fontello";
          font-size: 1rem;
          position: absolute;
          left: 15px;
          top: 50%;
          transform: rotate(180deg) translateY(50%);
        }
      }
    }

    ul &__link,
    .aside__menu_link,
    .aside__submenu_link {
      padding: 10px 15px;
      color: #000;
      font-weight: 400;
      display: block;
      line-height: 30px;
      font-size: 15px;
      cursor: pointer;
    }

    &__additional_header a {
      font-weight: 700 !important;
      font-size: 16px !important;
      color: @color_primary !important;
    }

    .submenu,
    .subsubmenu,
    .aside__submenu {
      width: calc(70vw - 60px);
      position: fixed;
      background: #fff;
      top: 0;
      height: 100vh;
      overflow: auto;
      transition: right 0.3s;
      z-index: 3;
      @media (max-width: 757px) {
        width: calc(100vw - 60px);
      }
    }

    .additional_menu {
      a {
        padding: 10px 15px;
        color: #000;
        font-weight: 400;
        display: block;
        line-height: 30px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
}

.mobile_menu_toggler {
  border: none;
  width: 64px;
  height: 64px;
  text-align: center;
  cursor: pointer;
  font-size: 2rem;
  border-left: 1px solid @background_border;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  transition: transform 0.3s ease;
  background: @background;
  color: @background_color_text;
  @media @laptop {
    position: static;
  }
  &.menu_open {
    transform: translateX(calc(-100vw + 60px));
  }
}
</style>
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      totalBalance: {},
      totalBalanceArchive: {},
      incomes: [],
      charges: [],
      summary: [],
    }
  },
  getters: {
    totalBalance(state) {
      return state.totalBalance;
    },
    totalBalanceArchive(state) {
      return state.totalBalanceArchive;
    },
    charges(state) {
      return state.charges
    },
    incomes(state) {
      return state.incomes
    },
    summary(state) {
      return state.summary
    }
  },
  mutations: {
    SET_TOTAL_BALANCE(state, { totalBalance }) {
      state.totalBalance = { ...totalBalance };
    },
    SET_TOTAL_BALANCE_ARCHIVE(state, { totalBalanceArchive }) {
      state.totalBalanceArchive = { ...totalBalanceArchive };
    },
    SET_CHARGES(state, { charges }) {
      state.charges = { ...charges };
    },
    SET_INCOMES(state, { incomes }) {
      state.incomes = { ...incomes };
    },
    SET_SUMMARY(state, { summary }) {
      state.summary = { ...summary };
    }
  },
  actions: {
    fetchBalance(context, { dateStart, dateEnd, fetchBalance = false, archive = false }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("from", dateStart)
      formData.append("to", dateEnd)
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getBalanceHistory/from/${dateStart}/to/${dateEnd}/archive/${archive}`)
          .then((res) => {
            const tempIncomesObj = {};
            const tempChargesObj = {};
            for (const income in res.data) {
              if (income !== "all") {
                for (const currency in res.data[income]) {
                  if (res.data[income][currency] > 0) {
                    tempIncomesObj[income] = tempIncomesObj[income] || {};
                    tempIncomesObj[income][currency] = res.data[income][currency];
                  } else {
                    tempChargesObj[income] = tempChargesObj[income] || {};
                    tempChargesObj[income][currency] = res.data[income][currency];
                  }
                }
              }
            }
            context.commit("SET_INCOMES", {
              incomes: tempIncomesObj,
            });
            context.commit("SET_CHARGES", {
              charges: tempChargesObj,
            });
            context.commit("SET_SUMMARY", { summary: res.data.all });
            if (fetchBalance) {
              context.dispatch("fetchTotalBalance", {archive: archive}).then(() => {
                resolve(true);
              });
            } else {
              context.dispatch("toggleLoader", false, { root: true });
              resolve(true);
            }

          }).catch((error) => {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error);
          })
      });
    },
    fetchTotalBalance(context, {archive = false}) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get('/api/Balance/getBalance').then((res) => {
          res.data.balance = parseFloat(res.data.balance);
          res.data.debt_limit = parseFloat(res.data.debt_limit);
          res.data.unpaid_invoices_amount = parseFloat(res.data.unpaid_invoices_amount);
          if (archive) {
            context.commit("SET_TOTAL_BALANCE", { totalBalance: { ...res.data.archive_client_data } });
            context.commit("SET_TOTAL_BALANCE_ARCHIVE", { totalBalanceArchive: { ...res.data.archive_client_data } });
          } else {
            context.commit("SET_TOTAL_BALANCE", { totalBalance: { ...res.data } });
            context.commit("SET_TOTAL_BALANCE_ARCHIVE", { totalBalanceArchive: { ...res.data.archive_client_data } });
          }
          context.dispatch("toggleLoader", false, { root: true });
          resolve(res);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        });
      })
    }
  }
}
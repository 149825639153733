import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      totalBalance: {},
      billingBalance: {},
      blockedAccounts: {},
      currenciesFormatted: [{
        id: 0,
        name: "PLN"
      }],
      currenciesFormattedIdoPayments: [{
        id: 0,
        name: "PLN"
      }],
      commission: {},
      withdrawalsLocked: false,
      withdrawalsLockedCauses: [

      ],
      withdrawalsLockedList: false,
      withdrawalsLockedCausesList: [

      ],
      waitingSaqDocument: 0,
      nextAutopayoffDate: "",
    }
  },
  getters: {
    totalBalance(state) {
      return state.totalBalance;
    },
    billingBalance(state) {
      return state.billingBalance;
    },
    currenciesFormatted(state) {
      return state.currenciesFormatted;
    },
    currenciesFormattedIdoPayments(state) {
      return state.currenciesFormattedIdoPayments;
    },
    blockedAccounts(state) {
      return state.blockedAccounts;
    },
    commission(state) {
      return state.commission;
    },
    withdrawalsLocked(state) {
      return state.withdrawalsLocked;
    },
    withdrawalsLockedCauses(state) {
      return state.withdrawalsLockedCauses;
    },
    withdrawalsLockedList(state) {
      return state.withdrawalsLockedList;
    },
    withdrawalsLockedCausesList(state) {
      return state.withdrawalsLockedCausesList;
    },
    waitingSaqDocument(state) {
      return state.waitingSaqDocument;
    },
    nextAutopayoffDate(state) {
      return state.nextAutopayoffDate;
    }
  },
  mutations: {
    SET_TOTAL_BALANCE(state, { totalBalance }) {
      state.totalBalance = { ...totalBalance };
    },
    SET_BILLING_BALANCE(state, { billingBalance }) {
      state.billingBalance = { ...billingBalance };
    },
    SET_CURRENCIES_FORMATTED(state, { currenciesArray }) {
      state.currenciesFormatted = [...currenciesArray];
    },
    SET_CURRENCIES_IDOPAYMENTS_FORMATTED(state, { currenciesIdoPayArray }) {
      state.currenciesFormattedIdoPayments = [...currenciesIdoPayArray];
    },
    SET_BLOCKED_ACCOUNTS(state, {blockedAccounts}) {
      state.blockedAccounts = { ...blockedAccounts};
    },
    SET_COMMISSION(state, {commission}) {
      state.commission = { ...commission};
    },
    SET_WITHDRAWALS_LOCKED(state, withdrawalsLocked) {
      state.withdrawalsLocked = withdrawalsLocked;
    },
    SET_WITHDRAWALS_LOCKED_CAUSES(state, {withdrawalsLockedCauses}) {
      state.withdrawalsLockedCauses = { ...withdrawalsLockedCauses};
    },
    SET_WITHDRAWALS_LOCKED_LIST(state, withdrawalsLockedList) {
      state.withdrawalsLockedList = withdrawalsLockedList;
    },
    SET_WITHDRAWALS_LOCKED_CAUSES_LIST(state, {withdrawalsLockedCausesList}) {
      state.withdrawalsLockedCausesList = { ...withdrawalsLockedCausesList};
    },
    SET_WAITING_SAQ_DOCUMENT(state, waitingSaqDocument) {
      state.waitingSaqDocument = waitingSaqDocument;
    },
    SET_NEXT_AUTOPAYOFF_DATE(state, nextAutopayoffDate) {
      state.nextAutopayoffDate = nextAutopayoffDate;
    }
  },
  actions: {
    fetchBalance(context, withdrawalType) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/payments/balance/withdrawalType/${withdrawalType}`).then((res) => {
          // Zaślepka na starą odpowiedź z API
          if (!res.data.balance.balance) {
            res.data.balance = {balance: res.data.balance};
          }

          context.commit("SET_TOTAL_BALANCE", { totalBalance: res.data.balance });
          context.commit("SET_BILLING_BALANCE", { billingBalance: res.data.billing_balance });
          context.commit("SET_COMMISSION", {commission: res.data.commission});
          context.commit("SET_BLOCKED_ACCOUNTS", { blockedAccounts: res.data.blocked_accounts});
          context.commit("SET_WITHDRAWALS_LOCKED", res.data.withdrawals_locked);
          context.commit("SET_WITHDRAWALS_LOCKED_CAUSES", { withdrawalsLockedCauses: res.data.withdrawals_locked_causes});
          context.commit("SET_WITHDRAWALS_LOCKED_LIST", res.data.withdrawals_locked_list);
          context.commit("SET_WITHDRAWALS_LOCKED_CAUSES_LIST", { withdrawalsLockedCausesList: res.data.withdrawals_locked_causes_list});
          context.commit("SET_WAITING_SAQ_DOCUMENT", res.data.waiting_saq_document);
          context.commit("SET_NEXT_AUTOPAYOFF_DATE", res.data.next_autopayoff_date);

          const currenciesArray = [];
          const currenciesIdoPayArray = [];
          for (const type in res.data.balance) {
            for (const currency in res.data.balance[type]) {
              if (type === 'balance') {
                currenciesArray.push({id: currency + '-' + type, name: currency, type: type});
              } else if (type === 'idopayments') {
                currenciesIdoPayArray.push({id: currency + '-' + type, name: currency, type: type});
              }
            }
          }

          context.commit("SET_CURRENCIES_FORMATTED", { currenciesArray });
          context.commit("SET_CURRENCIES_IDOPAYMENTS_FORMATTED", { currenciesIdoPayArray });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      });
    },
    makeWithdrawal(context, { source, amount_gross, currency, type, possibleDonation, unpaidInvoicesAmount, underLimit }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("source", source)
      formData.append("amount_gross", amount_gross)
      formData.append("currency", currency)
      formData.append("type", type)
      return new Promise((resolve, reject) =>
          axios.post("/api/payments/add_payment_request", formData).then(res => {
            context.commit("SET_TOTAL_BALANCE", {totalBalance: res.data.balance});
            context.dispatch("fetchBalance");
            resolve(true);
          }).catch(error => {
            context.dispatch("fetchBalance");
            context.dispatch(
                "internalServerError",
                {
                  code: error.response?.headers["error-message"],
                  param1: possibleDonation,
                  param2: unpaidInvoicesAmount,
                  param3: underLimit,
                },
                {root: true});
            context.dispatch("toggleLoader", false, {root: true});
            reject(error);
          }));
    },
  }
}
import axios from "@/axios"

export default {
  namespaced: true,
  state() {
    return {
      details: [],
      pages: 1,
      allTransactionIds: null,
    }
  },
  getters: {
    details(state) {
      return state.details
    },
    pages(state) {
      return state.pages
    },
    allTransactionIds(state) {
      return state.allTransactionIds
    }
  },
  mutations: {
    SET_DETAILS(state, { details }) {
      state.details = [...details]
    },
    SET_PAGES(state, { pages }) {
      state.pages = pages;
    },
    SET_ALL_TRANSACTION_IDS(state, allTransactionIds) {
      state.allTransactionIds = allTransactionIds;
    }
  },
  actions: {
    fetchDetails(context, { formData }) {
      context.dispatch("toggleLoader", true, { root: true });
      let formDataObj = new FormData();
      let hash = '';
      for (const key in formData) {
        if (key === 'page') {
          continue;
        }
        formDataObj.append(key, formData[key] ?? '');
        hash += formData[key] ?? '';
      }
      const page = formData['page'];
      const limit = 20;

      return new Promise((resolve, reject) => {

        function getTransactionByPage(allTransactionIds) {

          if (allTransactionIds.length === 0) {
            context.commit("SET_PAGES", { pages: 0 })
            context.commit("SET_DETAILS", { details: [] })
            context.dispatch("toggleLoader", false, { root: true });
            resolve(true)
            return;
          }
          let pagesNumber = Math.ceil(allTransactionIds.length / limit);
          let startIndex = limit * (page - 1);
          const pageTransactionIds = allTransactionIds.slice(startIndex, startIndex + limit);

          formDataObj = new FormData();
          formDataObj.append("transaction_company_ids_json", JSON.stringify(pageTransactionIds));
          formDataObj.append('system', formData['system'] ?? '');

          axios.post("/api/payments/search_payments_by_ids_and_company", formDataObj).then((res) => {
            const tempArray = [];
            for (const data in res.data.data) {
              tempArray.push(res.data.data[data])
            }
            context.commit("SET_PAGES", { pages: pagesNumber })
            context.commit("SET_DETAILS", { details: tempArray })
            context.dispatch("toggleLoader", false, { root: true });
            resolve(true)
          }).catch((error) => {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error)
          })
        }

        let cachedData = context.getters.allTransactionIds;
        if (cachedData !== null && cachedData.hash !== hash) {
          cachedData = null;
          context.commit("SET_ALL_TRANSACTION_IDS", null);
        }

        if (cachedData === null) {
          axios.post("/api/payments/search_payments_ids", formDataObj).then((transactionIdsData) => {
            const allTransactionIds = [];
            const transactionIds = transactionIdsData.data;

            for (const [company, value] of Object.entries(transactionIds)) {
              if (value.length === 0) {
                continue;
              }
              for (let index = 0; index < value.id.length; index++) {
                allTransactionIds.push({
                  'date': value.date[index],
                  'id': value.id[index],
                  'type': value.type[index],
                  'company': company
                })
              }
            }

            allTransactionIds.sort(function (transactionA, transactionB) {
              if (transactionA.date === transactionB.date) {
                return 0;
              }

              return (transactionA.date > transactionB.date ? -1 : 1);
            })

            cachedData = {
              allTransactionIds: allTransactionIds,
              hash: hash,
            };
            context.commit("SET_ALL_TRANSACTION_IDS", cachedData);

            getTransactionByPage(allTransactionIds);
          }).catch((error) => {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error);
          });
        } else {
          getTransactionByPage(cachedData.allTransactionIds);
        }
      })
    }
  }
}
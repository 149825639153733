import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      smsDetails: [],
      count: 0,
    }
  },
  getters: {
    all(state) {
      return state.smsDetails;
    },
    count(state) {
      return state.count;
    },
  },
  mutations: {
    SET_SMS(state, { smsDetails }) {
      state.smsDetails = [...smsDetails];
    },
    SET_COUNT(state, { count }) {
      state.count = count;
    }
  },
  actions: {
    setSms(context, { date, page }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getSms/from/${date}/to/${date}/details/true/page/${page}`
          // , {
          //   from: dateStart,
          //   to: dateEnd,
          //   currency,
          //   group,
          //   page,
          //   limit: 20,
          // }
        ).then((res) => {
          if(res.data.value.data) {
            context.commit("SET_SMS", { smsDetails: res.data.value.data })
          }
          if(res.data.value.count >= 0) {
            context.commit("SET_COUNT", { count: res.data.value.count })
          }
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    }
  }
}
import { createRouter, createWebHistory } from 'vue-router'

import account from './account';
import dashboard from './dashboard';
import invoices from './invoices';
import support from './support';
import balance from './balance';
import payments from './payments';
import shoprentergo from './shoprentergo';

const routes = [
  dashboard,
  account,
  support,
  invoices,
  balance,
  payments,
  shoprentergo,
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router

import axios from "@/axios"

export default {
  namespaced: true,
  state() {
    return {
      list: [],
      pages: 1,
    }
  },
  getters: {
    list: (state) => (currency, type) => {
      return state.list.filter(item => item.currency === currency && item.balance_type === type)
    },
    pages(state) {
      return state.pages
    }
  },
  mutations: {
    SET_LIST(state, { withdrawalsPending }) {
      state.list = [...withdrawalsPending]
    },
    REMOVE_ITEM(state, id) {
      state.list = state.list.filter(item => {
        return item.payment_id !== id
      });
    },
    SET_PAGES(state, { pages }) {
      state.pages = pages;
    }
  },
  actions: {
    fetchAllPayments(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/payments/waiting_payoff").then((res) => {
          context.commit("SET_LIST", { withdrawalsPending: res.data.data });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      });
    },
    /**
     * @param {*} context
     * @param {*} { dateStart, dateEnd, payment_target, page }
     */
    searchPayments(context, payload) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      formData.append("operation_mode", "only_payoff");
      formData.append("limit", "20");
      axios.post(`/api/payments/search_payments`, formData).then((res) => {
        context.commit("SET_LIST", { withdrawalsPending: [...res.data.data] })
        context.commit("SET_PAGES", { pages: res.data.pages })
        context.dispatch("toggleLoader", false, { root: true });
      }).catch((error) => {
        context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
        context.dispatch("toggleLoader", false, { root: true });
      })
    },
    cancelPayment(context, id) {
      context.dispatch("toggleLoader", true, { root: true });
      axios.get(`/api/payments/reject_payoff/id/${id}`).then(() => {
        context.commit("REMOVE_ITEM", id)
        context.dispatch("toggleLoader", false, { root: true });
      }).catch((error) => {
        context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
        context.commit("REMOVE_ITEM", id)
        context.dispatch("toggleLoader", false, { root: true });
      })
    }
  }
}
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      serverName: "",
      serverStatus: "",
      sla: [],
      comments: [],
    }
  },
  getters: {
    serverName(state) {
      return state.serverName;
    },
    serverStatus(state) {
      return state.serverStatus;
    },
    sla(state) {
      return state.sla;
    },
    comments(state) {
      return state.comments
    }
  },
  mutations: {
    SET_SERVER_NAME(state, { name }) {
      state.serverName = name;
    },
    SET_SERVER_STATUS(state, { status }) {
      state.serverStatus = status;
    },
    SET_SLA(state, { sla }) {
      state.sla = sla;
    },
    SET_COMMENTS(state, { comments }) {
      state.comments = [...comments];
    }
  },
  actions: {
    fetchServerData(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/account/system-status").then((res) => {
          const { server, status, sla, comments } = res.data;
          context.commit("SET_SERVER_NAME", { name: server });
          context.commit("SET_SERVER_STATUS", { status });
          if (Object.keys(sla).length && sla.error === undefined) {
            context.commit("SET_SLA", { sla });
          }
          context.commit("SET_COMMENTS", { comments });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    }
  }
}
import Alert from './Alert.vue';
import Heading from './Heading.vue';
import InputCheckbox from './InputCheckbox.vue';
import UploadDragAndDrop from './UploadDragAndDrop.vue';
import Textarea from './Textarea.vue';
import Table from './Table.vue';
import Pagination from './Pagination.vue';
import Modal from './Modal.vue';
import InputText from './InputText.vue';
import InputSelect from './InputSelect.vue';
import InputRadio from './InputRadio.vue';
import Loader from './Loader.vue';

export default [
  Alert,
  Heading,
  InputRadio,
  InputSelect,
  InputText,
  Modal,
  Pagination,
  Table,
  Textarea,
  UploadDragAndDrop,
  InputCheckbox,
  Loader
];
<template>
  <div class="alert mb-2" :class="className">
    <div class="alert__icon"></div>
    <div class="alert__description">
      <strong v-if="label">{{ $t(label) }}: </strong><slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "label"],
  name: "Alert",
  computed: {
    className() {
      switch (this.type) {
        case "info":
          return "--info";
        case "warning":
          return "--warning";
        case "error":
          return "--error";
        case "success":
          return "--success";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/variables.less";

.alert {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  background: @background_alternate;
  border: 1px solid @background_alternate_border;

  @media @tablet {
    flex-direction: row;
  }

  &__icon {
    background-color: @color_info;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 15px 0;

    @media @tablet {
      min-width: 100px;
      max-width: 100px;
      padding: 0;
    }

    &:before {
      content: "\f05a";
      font-family: "fontello";
      font-weight: 900;
      font-size: 3.4rem;
      color: #fff;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 12px;
      border-style: solid;
      border-color: transparent;
      transform: translateX(-50%);
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 1;
      border-top-color: @color_info;

      @media @tablet {
        border-top-color: transparent;
        border-left-color: @color_info;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
      }
    }
  }

  &__description {
    padding: 25px 30px;
    font-size: 1.4rem;

    a {
      color: @background_alternate_color_link_hover;
      font-weight: bold;

      &:hover {
        color: @background_alternate_color_link;
      }
    }
  }

  &.--error {
    .alert__icon {
      background-color: @color_error;

      &:before {
        content: "\f057";
      }

      &:after {
        border-top-color: @color_error;

        @media @tablet {
          border-top-color: transparent;
          border-left-color: @color_error;
        }
      }
    }
  }

  &.--success {
    .alert__icon {
      background-color: @color_success;

      &:before {
        content: "\f058";
      }

      &:after {
        border-top-color: @color_success;

        @media @tablet {
          border-top-color: transparent;
          border-left-color: @color_success;
        }
      }
    }
  }

  &.--warning {
    .alert__icon {
      background-color: @color_warning;

      &:before {
        content: "\f06a";
      }

      &:after {
        border-top-color: @color_warning;

        @media @tablet {
          border-top-color: transparent;
          border-left-color: @color_warning;
        }
      }
    }
  }
}
</style>
<template>
  <div class="form__radio">
    <input
      type="radio"
      :id="id"
      class="form__input"
      :class="className"
      :value="label"
      :name="name"
      :required="required"
      v-model="radioButtonValue"
    />
    <label :for="id" class="form__label"><slot></slot></label>
  </div>
</template>

<script>
export default {
  name: "InputRadio",
  emits: ["change-value"],
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selectedValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    className: String,
    required: Boolean,
  },
  computed: {
    radioButtonValue: {
      get: function () {
        return this.selectedValue;
      },
      set: function () {
        this.$emit("change-value", this.label);
      },
    },
  },
};
</script>

<style>
</style>
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      taskList: {},
      balance: {},
      approveError: null,
      rejectError: null,
    }
  },
  getters: {
    all(state) {
      return state.taskList;
    },
    balance(state) {
      return state.balance;
    },
    approveError(state) {
      return state.approveError;
    },
    rejectError(state) {
      return state.rejectError;
    }
  },
  mutations: {
    SET_TASKS(state, { taskList }) {
      state.taskList = { ...taskList };
    },
    SET_BALANCE(state, { balance }) {
      state.balance = { ...balance };
    },
    SET_APPROVE_ERROR(state, { error }) {
      state.approveError = error ;
    },
    SET_REJECT_ERROR(state, { error }) {
      state.rejectError = error ;
    }
  },
  actions: {
    getTasks(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/balance/getUnapprovedJobs").then((res) => {
          if (typeof res.data.value !== "string") {
            context.commit('SET_TASKS', { taskList: res.data.value });
          } else {
            context.commit('SET_TASKS', {});
          }
          context.commit('SET_BALANCE', { balance: res.data.balance});
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
    approveTask(context, { id }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/approveJob/id/${id}`).then((res) => {
          context.dispatch("toggleLoader", false, { root: true });
          context.commit('SET_APPROVE_ERROR', {error: res.data.errno});
          context.commit('SET_REJECT_ERROR', {error: null});
          resolve(res.data)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
    rejectTask(context, { id }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/rejectJob/id/${id}`).then((res) => {
          context.dispatch("toggleLoader", false, { root: true });
          context.commit('SET_REJECT_ERROR', {error: res.data.errno});
          context.commit('SET_APPROVE_ERROR', {error: null});
          resolve(res.data)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
    clearError(context)
    {
      context.commit('SET_APPROVE_ERROR', {error: null});
      context.commit('SET_REJECT_ERROR', {error: null});
    }
  },
}
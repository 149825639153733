<template>
  <div
      class="upload_files"
      :class="{ '--dragover': dragOver, '--file_dropped': fileDropped }"
      v-on="handlers"
  >
    <Alert v-if="this.errors.upload_max_filesize" label="Ostrzezenie" type="warning">
      {{ $t('PrzekroczonoMaksymalnaWielkoscPojedynczegoZalacznika') }}
    </Alert>
    <Alert v-if="this.errors.post_max_size" label="Ostrzezenie" type="warning">
      {{ $t('PrzekroczonoMaksymalnaWielkoscWszystkichZalacznikow') }}
    </Alert>
    <Alert v-if="this.errors.max_file_uploads" label="Ostrzezenie" type="warning">
      {{ $t('PrzekroczonoMaksymalnaIloscDodanychZalacznikow') }}
    </Alert>
    <Alert v-if="this.errors.available_file_extensions" label="Ostrzezenie" type="warning">
      {{ $t('FormatPlikuNieJestObslugiwany') + ' ' + $t('ObslugiwaneFormatyToX').format(this.$available_file_extensions.join(', ')) }}
    </Alert>
    <input
        @input.stop.prevent="inputFile"
        class="upload_files__file d-none"
        type="file"
        id="file"
        ref="inputFile"
        :multiple="multiple"
    />
    <label class="upload_files__label" for="file">
      <span class="upload_files__img d-block mb-5"
      ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="67.078"
          height="63.804"
          viewBox="0 0 67.078 63.804"
      >
          <path
              d="M13672.9-6713.011a8.186,8.186,0,0,1-8.033-7.954v-19.423a.921.921,0,0,1,.31-.624l10.3-18.875a1.487,1.487,0,0,1,1.4-.7h5.928a1.56,1.56,0,0,1,1.562,1.562,1.557,1.557,0,0,1-1.562,1.558h-4.99l-8.581,15.6h8.736a10.214,10.214,0,0,1,9.359,6.007,7.249,7.249,0,0,0,6.552,4.132h9.05a7.248,7.248,0,0,0,6.552-4.132,10.216,10.216,0,0,1,9.359-6.007h8.892l-7.254-15.6H13714a1.556,1.556,0,0,1-1.561-1.558,1.559,1.559,0,0,1,1.561-1.562h7.485a1.408,1.408,0,0,1,1.406.782l8.813,18.875a1.144,1.144,0,0,1,.234.544v19.423a8.187,8.187,0,0,1-8.033,7.954Zm-4.915-7.954a5.072,5.072,0,0,0,4.915,4.835h51.012a5.072,5.072,0,0,0,4.914-4.835v-17.786h-9.984a7.256,7.256,0,0,0-6.551,4.135,10.229,10.229,0,0,1-9.36,6.007h-9.05a10.226,10.226,0,0,1-9.36-6.007,7.256,7.256,0,0,0-6.551-4.135h-9.984Zm29.328-19.737-10.607-10.684a1.556,1.556,0,0,1,0-1.871,1.561,1.561,0,0,1,2.185-.314l7.954,7.958v-29.641a1.562,1.562,0,0,1,1.562-1.561,1.561,1.561,0,0,1,1.561,1.561v29.641l7.954-7.958a1.556,1.556,0,0,1,1.871,0,1.561,1.561,0,0,1,.313,2.185l-10.607,10.684c-.03.034-.059.062-.089.093a1.478,1.478,0,0,1-1,.388A1.487,1.487,0,0,1,13697.309-6740.7Z"
              transform="translate(-13664.862 6776.815)"
              fill="#43d3f7"
          /></svg></span
      ><span class="upload_files__title"
    >{{ $t("PrzeciagnijTutajI") }}
        <strong class="upload_files__dragndrop">
          {{ $t("UpuscPliki") }}</strong
        ></span
    >
      <span class="upload_files__click d-block mt-2 mb-5">{{
          $t("MozeszTezKliknacAbyDodacPliki")
        }}
      </span>
    </label>
  </div>
</template>

<script>
import Alert from "@/components/base/Alert.vue";
import mime from 'mime';

export default {
  components: {Alert},
  emits: ["input-files"],
  name: "UploadDragAndDrop",
  props: {
    alreadyAddedFiles: Object,
    multiple: Boolean,
  },
  data() {
    return {
      isDraggable: false,
      handlers: {
        drag: this.prevent,
        dragstart: this.prevent,
        dragend: this.dragLeaveHandler,
        dragover: this.dragOverHandler,
        dragenter: this.dragOverHandler,
        dragleave: this.dragLeaveHandler,
        drop: this.dropFile,
      },
      errors: {
        max_file_uploads: false,
        upload_max_filesize: false,
        post_max_size: false,
        available_file_extensions: false
      },
      files: [],
      dragOver: false,
      fileDropped: false,
      alreadyAddedFilesSize: 0,
      addedFilesSize: 0
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    prevent(ev) {
      ev.preventDefault();
      ev.stopPropagation();
    },
    dragOverHandler(ev) {
      this.prevent(ev);
      this.dragOver = true;
    },
    dragLeaveHandler(ev) {
      this.prevent(ev);
      this.dragOver = false;
    },
    dropFile(ev) {
      this.files = [];
      this.clearErrors();
      this.dragLeaveHandler(ev);
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === "file") {
            if (!this.errors.max_file_uploads && ev.dataTransfer.items.length + this.alreadyAddedFiles.length > this.$max_file_uploads) {
              this.errors.max_file_uploads = true;
            }
            if (!this.errors.upload_max_filesize && ev.dataTransfer.items[i].getAsFile().size > this.$upload_max_filesize * 1000000) {
              this.errors.upload_max_filesize = true;
            }
            if (!this.errors.post_max_size && (this.getAlreadyAddedFilesSize() + this.getAddedFilesSize(ev.dataTransfer.items)) / 1000000 > this.$post_max_size) {
              this.errors.post_max_size = true;
            }
            if (!this.errors.available_file_extensions) {
              this.errors.available_file_extensions = !this.$available_file_extensions.includes(mime.getExtension(ev.dataTransfer.items[i].getAsFile().type));
            }
            const file = {
              content: ev.dataTransfer.items[i].getAsFile(),
              sizeFormatted: this.formatBytes(
                  ev.dataTransfer.items[i].getAsFile().size
              ),
            };
            this.files.push(file);
          }
        }
      } else {
        for (let y = 0; y < ev.dataTransfer.files.length; y++) {
          if (!this.errors.max_file_uploads && ev.dataTransfer.files.length + this.alreadyAddedFiles.length > this.$max_file_uploads) {
            this.errors.max_file_uploads = true;
          }
          if (!this.errors.upload_max_filesize && ev.dataTransfer.files[y].size > this.$upload_max_filesize * 1000000) {
            this.errors.upload_max_filesize = true;
          }
          if (!this.errors.post_max_size && (this.getAlreadyAddedFilesSize() + this.getAddedFilesSize(ev.dataTransfer.files)) / 1000000 > this.$post_max_size) {
            this.errors.post_max_size = true;
          }
          if (!this.errors.available_file_extensions) {
            this.errors.available_file_extensions = !this.$available_file_extensions.includes(mime.getExtension(ev.dataTransfer.files[y].type));
          }
          const file = {
            content: ev.dataTransfer.files[y],
            sizeFormatted: this.formatBytes(ev.dataTransfer.files[y].size),
          };
          this.files.push(file);
        }
      }
      if (this.isValid()) {
        this.sendFiles();
      } else {
        ev.dataTransfer.clearData();
        return false;
      }
    },
    inputFile(ev) {
      this.files = [];
      this.clearErrors();
      ev.preventDefault();
      ev.stopPropagation();
      for (let y = 0; y < ev.target.files.length; y++) {
        if (!this.errors.max_file_uploads && ev.target.files.length + this.alreadyAddedFiles.length > this.$max_file_uploads) {
          this.errors.max_file_uploads = true;
        }
        if (!this.errors.upload_max_filesize && ev.target.files[y].size > this.$upload_max_filesize * 1000000) {
          this.errors.upload_max_filesize = true;
        }
        if (!this.errors.post_max_size && (this.getAlreadyAddedFilesSize() + this.getAddedFilesSize(ev.target.files)) / 1000000 > this.$post_max_size) {
          this.errors.post_max_size = true;
        }
        if (!this.errors.available_file_extensions) {
          this.errors.available_file_extensions = !this.$available_file_extensions.includes(mime.getExtension(ev.target.files[y].type));
        }
        const file = {
          content: ev.target.files[y],
          sizeFormatted: this.formatBytes(ev.target.files[y].size),
        };
        this.files.push(file);
      }
      if (this.isValid()) {
        this.sendFiles();
      } else {
        this.$refs.inputFile.value = '';
        return false;
      }
    },
    sendFiles() {
      this.$emit("input-files", this.files);
    },
    isValid() {
      return Object.values(this.errors).every(error => error === false)
    },
    clearErrors() {
      Object.keys(this.errors).forEach(key => this.errors[key] = false)
    },
    getAlreadyAddedFilesSize() {
      if (!this.alreadyAddedFilesSize) {
        for (const i in this.alreadyAddedFiles) {
          this.alreadyAddedFilesSize += this.alreadyAddedFiles[i].content.size;
        }
      }
      return this.alreadyAddedFilesSize;
    },
    getAddedFilesSize(addedFiles) {
      this.addedFilesSize = 0;
      for (let i = 0; i < addedFiles.length; i++) {
        if ('getAsFile' in addedFiles[i]) {
          this.addedFilesSize += addedFiles[i].getAsFile().size;
        } else {
          this.addedFilesSize += addedFiles[i].size;
        }
      }
      return this.addedFilesSize;
    }
  },
};
</script>

<style lang="less">
.upload_files {
  &__label {
    cursor: pointer;
    padding: 55px;
    display: block;
    text-align: center;
  }

  &__title {
    font-size: 2.6rem;
  }

  &__click {
    font-size: 1.5rem;
  }
}
</style>
export default {
  meta: { breadCrumb: 'ShoprenterGo' },
  path: '/shoprentergo',
  name: 'shoprentergo',
  component: () => import('@/views/shoprentergo/ShoprenterGo.vue'),
  children: [
    {
      meta: { breadCrumb: 'ListOfChargedPackages' },
      path: 'packages',
      component: () => import('@/views/shoprentergo/Packages.vue'),
    },
    {
      meta: { breadCrumb: 'WyplataSrodkow' },
      path: 'withdraw',
      component: () => import('@/views/shoprentergo/Withdrawal.vue'),
    },
    {
      meta: { breadCrumb: 'ListaWplat' },
      path: 'list',
      component: () => import('@/views/shoprentergo/List.vue'),
    },
    {
      meta: { breadCrumb: 'HistoriaWyplat' },
      path: 'withdrawal_history',
      component: () => import('@/views/shoprentergo/WithdrawalHistory.vue'),
    },
    {
      meta: { breadCrumb: 'Szczegoly' },
      path: 'details',
      props: route => ({...route.query}),
      component: () => import('@/views/shoprentergo/Details.vue'),
    },
  ]
}
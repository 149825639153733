import axios from "@/axios"

export default {
  namespaced: true,
  state() {
    return {
      accounts: [],
      verificationData: {}
    }
  },
  getters: {
    accountsIAI(state) {
      return state.accountsIAI
    },
    accountsIdoPayments(state) {
      return state.accountsIdoPayments
    },
    verificationData(state) {
      return state.verificationData
    }
  },
  mutations: {
    SET_ACCOUNTS_IAI(state, {bankAccounts}) {
      state.accountsIAI = {...bankAccounts};
    },
    SET_ACCOUNTS_IDOPAYMENTS(state, {bankAccounts}) {
      state.accountsIdoPayments = {...bankAccounts};
    },
    CHANGE_ACCOUNT(state, {currency, account_number}) {
      Object.keys(state.accounts).map((key) => {
        if (key === currency) {
          Object.keys(state.accounts[key].options).map((no) => {
            if (state.accounts[key].options[no].account_number === account_number) {
              state.accounts[key].account_number = state.accounts[key].options[no].account_number;
              state.accounts[key].account_number_formatted = state.accounts[key].options[no].account_number_formatted;
              state.accounts[key].swift = state.accounts[key].options[no].swift;
            }
          })
        }
      })
    },
    CHANGE_VERIFICATION_DATA(state, {verificationData}) {
      state.verificationData = {...verificationData}
    }
  },
  actions: {
    fetchAccountsData(context) {
      context.dispatch("toggleLoader", true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get("/api/payments/payoff_settings/").then((res) => {
          let dataIAI = {};
          let dataIdoPayments = {};

          for (const company in res.data.selectedAccounts) {
            if (company == 'IAI') {
              dataIAI = {
                company: company,
                bankAccounts:
                    res.data.bankAccounts[company]
                ,
                selectedAccounts:
                    res.data.selectedAccounts[company]
              }
            } else if (company == 'IdoPayments') {
              dataIdoPayments = {
                company: company,
                bankAccounts:
                    res.data.bankAccounts[company]
                ,
                selectedAccounts:
                    res.data.selectedAccounts[company]
              }
            }
          }

          const accountsDataIAI = {};
          const accountsDataIdoPayments = {};

          for (const currency in dataIAI.selectedAccounts) {
            accountsDataIAI[currency] = {
              company: dataIAI.company,
              currency,
                ...dataIAI.selectedAccounts[currency],
              options: [
                ...dataIAI.bankAccounts[currency]
              ]
            }
            accountsDataIdoPayments[currency] = {
              company: dataIdoPayments.company,
              currency,
                ...dataIdoPayments.selectedAccounts[currency],
              options: [
                ...dataIdoPayments.bankAccounts[currency]
              ]
            }
          }

          context.commit("SET_ACCOUNTS_IAI", {bankAccounts: accountsDataIAI});
          context.commit("SET_ACCOUNTS_IDOPAYMENTS", {bankAccounts: accountsDataIdoPayments});
          context.dispatch("toggleLoader", false, {root: true});
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
          context.dispatch("toggleLoader", false, {root: true});
          reject(true);
        })
      });
    },
    fetchVerificationTransfer(context, {currency, company}) {
      context.dispatch("toggleLoader", true, {root: true});
      return new Promise((resolve, reject) => {
        axios.get(`/api/payments/verification_transfer/currency/${currency}/company/${company}`).then((res) => {
          const verificationData = {...res.data, ...res.data.receiver, amount: `1.00 ${currency}`};
          verificationData.address = `${verificationData.address}, ${verificationData.zipcode} ${verificationData.city} ${verificationData.country}`;
          delete verificationData.receiver;
          context.commit("CHANGE_VERIFICATION_DATA", {verificationData})
          context.dispatch("toggleLoader", false, {root: true});
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
          context.dispatch("toggleLoader", false, {root: true});
          reject(error);
        });
      });
    },
    changeAccount(context, {account_number, pin, currency, company}) {
      context.dispatch("toggleLoader", true, {root: true});
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("account_number", account_number)
        formData.append("pin", pin)
        formData.append("currency", currency)
        formData.append("company", company)
        axios.post(`/api/payments/save_bank_account`, formData).then(() => {
          context.commit("CHANGE_ACCOUNT", {currency, account_number})
          context.dispatch("toggleLoader", false, {root: true});
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
          context.dispatch("toggleLoader", false, {root: true});
          reject(error);
        });
      });

    },
  }
}
<template>
  <router-link :to="`/support/ticket/${id}`" class="ticket_item d-block">
    <div class="ticket_item__container d-flex py-3">
      <div
        class="ticket_item__icon d-flex justify-content-center align-items-center"
        :class="typeFormatted"
      ></div>
      <div class="ticket_item__content">
        <div class="ticket_item__title mb-1">{{ `#${id} - ${title}` }}</div>
        <div class="ticket_item__subtitle d-flex">
          <div
            class="ticket_item__responses d-flex justify-content-center align-items-center"
          >
            {{ answ }}
          </div>
          <div class="ticket_item__recent_response">
            <span>{{ `${$t("OstatniaOdpowiedz")}: ` }} </span>
            <strong>{{ last_creator }}</strong>
            <span>, {{ last_date }}</span>
          </div>
        </div>
      </div>
      <div
        class="ticket_item__button d-none d-sm-flex justify-content-center align-items-center"
      >
        <i class="icon-angle_right"></i>
      </div>
    </div>
    <div class="ticket_item__sub d-flex justify-content-between flex-wrap">
      <div class="ticket_item__created_by">
        <span>{{ `${$t("UtworzonyPrzez")}: ` }} </span
        ><strong>{{ creator }}</strong
        ><span>, {{ first_date }}</span>
      </div>
      <div v-if="jobs > 0" class="ticket_item__tasks">
        <div class="ticket_item__tasks_icon d-inline pr-2">
          <i class="icon-tasks"></i>
        </div>
        <span>{{ `${$t("PrzypisanychZadan")}: ` }} </span
        ><strong>{{ jobs }} </strong>
        <span v-if="waitingForAccept > 0"
          >, {{ `${$t("OczekujeNaAkceptacje")}: ` }} </span
        ><strong v-if="waitingForAccept > 0">{{ waitingForAccept }}</strong>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "graphics",
    },
    title: String,
    id: String,
    answ: String,
    creator: String,
    first_date: String,
    last_creator: String,
    last_date: String,
    jobs: Number,
    close_date: String,
    waitingForAccept: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    typeFormatted() {
      switch (this.type) {
        case "1":
          return "support";
        case "2":
          return "support";
        case "3":
          return "support";
        case "4":
          return "settlement_dept";
        case "5":
          return "support";
        case "6":
          return "settlement_dept";
        case "7":
          return "settlement_dept";
        case "8":
          return "settlement_dept";
        case "9":
          return "settlement_dept";
        case "10":
          return "settlement_dept";
        case "11":
          return "support";
        case "12":
          return "graphics";
        case "13":
          return "developers_support";
        case "14":
          return "support";
        case "15":
          return "faq";
        case "16":
          return "support";
        case "17":
          return "support";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.ticket_item {
  border: 1px solid @background_border;
  transition: box-shadow 0.3s ease;
  margin-bottom: 20px;
  color: @background_color_text;
  &:hover {
    box-shadow: @box-shadow_hover;
    color: @background_color_text;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__icon {
    width: 30px;
    @media @tablet {
      width: 70px;
      flex-shrink: 0;
    }
    position: relative;
    font-size: 1.8rem;
    &::before {
      content: "";
      width: 5px;
      height: calc(100% + 30px);
      top: -15px;
      left: 0;
      position: absolute;
    }
    &::after {
      font-family: fontello;
      @media (max-width: 757px) {
        display: none;
      }
    }
    &.graphics {
      color: @color_success;
      &::before {
        background: @color_success;
      }
      &::after {
        content: "\e804";
      }
    }
    &.support,
    &.settlement_dept,
    &.developers_support,
    &.settlement_dept {
      color: @color_orange;
      &::before {
        background: @color_orange;
      }
      &::after {
        font-size: 2.2rem;
        content: "\e805";
      }
    }
    &.phonecall {
      color: @color_purple;
      &::before {
        background: @color_purple;
      }
      &::after {
        font-size: 1.9rem;
        content: "\e80a";
      }
    }
    &.faq {
      color: @color_info;
      &::before {
        background: @color_info;
      }
      &::after {
        font-size: 1.9rem;
        content: "\e805";
      }
    }
  }
  &__responses {
    color: #fff;
    background: #000;
    font-size: 1.1rem;
    padding: 0 3px;
    min-width: 1.7rem;
    border-radius: 3px;
    position: relative;
    margin-right: 5px;
    height: 1.7rem;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 5px 0 0;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 4px;
    }
  }
  &__button {
    margin-left: auto;
    width: 60px;
    border-left: 1px solid @background_border;
    flex-shrink: 0;
  }
  &__content {
    overflow: hidden;
    text-overflow: ellipsis;
    @media @tablet {
      max-width: calc(100% - 130px);
    }
    padding-right: 10px;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__subtitle {
    font-size: 1.3rem;
    padding-bottom: 4px;
  }
  &__sub {
    font-size: 1.2rem;
    background: @background_alternate;
    padding: 10px 18px 10px 40px;
    position: relative;
    @media @tablet {
      padding: 6px 18px;
    }
  }
  &__recent_response {
    span:nth-child(1) {
      @media (max-width: 757px) {
        display: block;
      }
    }
  }
  &__tasks {
    &_icon {
      font-size: 1.1rem;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      @media @tablet {
        position: static;
        transform: none;
      }
    }
  }
}
</style>
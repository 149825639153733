<template>
  <div v-if="totalBalance.unpaid_invoices_amount > 0">
    <Alert label="Uwaga" type="error">
          <span v-if="$mode === 'booking'" v-html="$t('MaszNiezaplaconeFakturyVATLubDokumentyProFormaNaLacznaKwoteXProsimyOAHrefOptionAddpaymentDokonanieWplatyAAbyUniknacBlokadyWidgetu').format(
              $formatPrice(totalBalance.unpaid_invoices_amount, totalBalance.billing_currency),
              '/balance/payments',
          )"></span>
      <span v-else v-html="$t('MaszNiezaplaconeFakturyNaSumeX').format(
              $formatPrice(totalBalance.unpaid_invoices_amount, totalBalance.billing_currency),
              '/balance/payments',
          )"></span>
    </Alert>
  </div>
  <div v-else-if="totalBalance.balance > totalBalance.debt_limit">
    <Alert label="Informacja" v-if="totalBalance.debt_limit < 0">
          <span v-if="$mode === 'booking'" v-html="$t('fmtPostpaidMinimalneSaldoXZostaloYBooking').format(
              $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
              $formatPrice(-totalBalance.debt_limit, totalBalance.billing_currency),
              $formatPrice(totalBalance.balance - totalBalance.debt_limit, totalBalance.billing_currency),
          )"></span>
      <span v-else v-html="$t('fmtPostpaidMinimalneSaldoXZostaloY').format(
              $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
              $formatPrice(-totalBalance.debt_limit, totalBalance.billing_currency),
              $formatPrice(totalBalance.balance - totalBalance.debt_limit, totalBalance.billing_currency),
          )"></span>
    </Alert>
    <Alert label="Informacja" v-else>
      <span v-if="$mode === 'booking'" v-html="$t('fmtPrepaidMinimalneSaldoXZostaloYBooking').format(
          $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
          $formatPrice(totalBalance.balance - totalBalance.debt_limit, totalBalance.billing_currency),
          '/balance/payments',
          $formatPrice(-totalBalance.debt_limit, totalBalance.billing_currency),
      )"></span>
      <span v-else v-html="$t('fmtPrepaidMinimalneSaldoXZostaloY').format(
          $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
          $formatPrice(totalBalance.balance - totalBalance.debt_limit, totalBalance.billing_currency),
          '/balance/payments',
          $formatPrice(-totalBalance.debt_limit, totalBalance.billing_currency),
      )"></span>
    </Alert>
  </div>
  <div v-else-if="totalBalance.balance">
    <Alert label="Uwaga" type="error" v-if="totalBalance.debt_limit < 0">
          <span v-if="$mode === 'booking'" v-html="$t('fmtPostpaidMinimalneSaldoXPrzekroczoneBooking').format(
              $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
              '/balance/payments',
          )"></span>
      <span v-else v-html="$t('fmtPostpaidMinimalneSaldoXPrzekroczone').format(
              $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
              '/balance/payments',
          )"></span>
    </Alert>
    <Alert label="Uwaga" type="error"  v-else>
          <span v-if="$mode === 'booking'" v-html="$t('fmtPrepaidMinimalneSaldoXPrzekroczoneBooking').format(
              $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
              '/balance/payments',
          )"></span>
      <span v-else v-html="$t('fmtPrepaidMinimalneSaldoXPrzekroczone').format(
              $formatPrice(totalBalance.debt_limit, totalBalance.billing_currency),
              '/balance/payments',
          )"></span>
    </Alert>
  </div>
  <div class="mb-5" v-if="billingBalance && billingBalance.possible_donation.net > 0 && billingBalance.jobs_to_approve.net == 0">
    <Alert label="Ostrzezenie" type="warning">
          <span>{{
              $t("AktualnaWartoscTwojegoZadluzeniaWynosiX").format(
                  `${$formatPrice(billingBalance.possible_donation.net, billingBalance.billing_currency)} (${$formatPrice(
                      billingBalance.possible_donation.gross, billingBalance.billing_currency
                  )} ${$t("brutto")})`
              )}} {{
              $t("WynikaToZUjemnegoSalda")
            }} {{
              $t("KazdaNadplataPonadTaKwoteZostanieCiZwrocona")
            }}</span>
    </Alert>
  </div>
  <div class="mb-5" v-if="billingBalance && billingBalance.possible_donation.net > 0 && billingBalance.jobs_to_approve.net > 0 && billingBalance.balance.net < 0">
    <Alert label="Ostrzezenie" type="warning">
          <span>{{
              $t("AktualnaWartoscTwojegoZadluzeniaWynosiX").format(
                  `${$formatPrice(billingBalance.possible_donation.net, billingBalance.billing_currency)} (${$formatPrice(
                      billingBalance.possible_donation.gross, billingBalance.billing_currency
                  )} ${$t("brutto")})`
              )
            }} {{
              $t("WynikaToZXUjemnegoSaldaIXNiezaakceptowanychZadan").format(
                  `${$formatPrice(-billingBalance.balance.gross, billingBalance.billing_currency)}`,
                  `${$formatPrice(billingBalance.jobs_to_approve.gross, billingBalance.billing_currency)}`
              )
            }} {{
              $t("KazdaNadplataPonadTaKwoteZostanieCiZwrocona")
            }}</span>
    </Alert>
  </div>
  <div class="mb-5" v-if="billingBalance && billingBalance.possible_donation.net > 0 && billingBalance.jobs_to_approve.net > 0 && billingBalance.balance.net >= 0">
    <Alert label="Ostrzezenie" type="warning">
          <span>{{
              $t("AktualnaWartoscTwojegoZadluzeniaWynosiX").format(
                  `${$formatPrice(billingBalance.possible_donation.net, billingBalance.billing_currency)} (${$formatPrice(
                      billingBalance.possible_donation.gross, billingBalance.billing_currency
                  )} ${$t("brutto")})`
              )
            }} {{
              $t("WynikaToZNiezaakceptowanychZadan")
            }} {{
              $t("KazdaNadplataPonadTaKwoteZostanieCiZwrocona")
            }}</span>
    </Alert>
  </div>
</template>

<script>
import Alert from "@/components/base/Alert";
export default {
  components: {
    Alert
  },
  props: ['totalBalance', 'billingBalance'],
  name: "Alerts"
}
</script>

<style scoped>

</style>
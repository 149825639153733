<template>
  <div class="summary_heading pb-4" :class="{ bordered: bordered }">
    <div
      class="summary_heading__container d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-start"
    >
      <div class="summary_heading__content">
        <span class="pr-2">{{ $t(literal) }}</span>
        <strong v-if="value" :class="className">{{ value }}</strong>
        <strong v-for="(value, key) in values" :key="key" :class="className"
          >{{ value
          }}<span v-if="key !== values.length - 1" class="pr-2">,</span></strong
        >
      </div>
      <div v-if="link" class="summary_heading__details ml-sm-3 mt-3 mt-sm-0 flex-shrink-0">
        <router-link :to="link">{{ $t(linkValue) }}</router-link>
      </div>
      <div v-if="multiLinks" class="summary_heading__multilinks summary_heading__details ml-sm-3 mt-3 mt-sm-0 flex-shrink-0">
        <router-link v-for="(value, key) in multiLinks" :to="value" :key="key"> {{ $t(key) }} </router-link>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    literal: String,
    className: String,
    value: String,
    values: Array,
    link: String,
    linkValue: String,
    multiLinks: Object,
    bordered: Boolean,
  },
};
</script>

<style lang="less">
@import "../styles/variables.less";

.summary_heading {
  &.bordered {
    border-bottom: 1px solid @background_border;
  }
  &__content {
    font-size: 2.6rem;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 757px) {
      width: 100%;
    }
    strong {
      white-space: nowrap;
      &.--color_success {
        color: @color_primary;
      }
      &.--color_warning {
        color: @color_warning;
      }
      &.--color_error {
        color: @color_error;
      }
      &.--color_info {
        color: @color_info;
      }
    }
  }
  &__details a {
    color: @color_primary;
    font-size: 1.2rem;
    &:hover {
      color: @background_color_text;
    }
  }
  &__multilinks {
    display: flex;
    flex-direction: column;
  }
}
</style>
<template>
  <div
    class="pagination d-flex justify-content-start justify-content-sm-center mx-n1 flex-wrap mt-5"
    :class="className"
  >
    <div
      class="pagination__item mx-1"
      :class="{
        '--gap': item.type === 'gap',
        '--active': item.number === currentPage,
      }"
      v-for="item in paginationPages"
      :key="item.number"
    >
      <span
        class="btn --solid --black px-0 mb-2"
        v-if="item.number === currentPage"
      >
        {{ item.number }}
      </span>
      <button
        class="btn --solid px-0 mb-2"
        v-else-if="item.type === 'page'"
        @click="selectPage(item.number)"
      >
        {{ item.number }}
      </button>
      <span class="btn --solid px-0 mb-2" v-else>...</span>
    </div>
    <div
      class="pagination__next_page flex-grow-1 flex-sm-grow-0"
      :class="{ '--disabled': currentPage === totalPages }"
      @click="selectPage(currentPage + 1)"
    >
      <button class="btn --solid --black mb-2">
        {{ $t("NastepnaStrona") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["selected-page"],
  name: "Pagination",
  props: {
    className: String,
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    paginationPages() {
      const pages = [];
      if (this.currentPage >= 5 && this.totalPages > 5) {
        pages.push({
          type: "page",
          number: 1,
        });
        pages.push({
          type: "gap",
          number: "#1",
        });
        if (this.totalPages - this.currentPage >= 4) {
          for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
            pages.push({
              type: "page",
              number: i,
            });
          }
          pages.push({
            type: "gap",
            number: "#x",
          });
          pages.push({
            type: "page",
            number: this.totalPages,
          });
        } else {
          for (let i = this.totalPages - 4; i <= this.totalPages; i++) {
            pages.push({
              type: "page",
              number: i,
            });
          }
        }
      } else if (this.currentPage < 5 && this.totalPages > 7) {
        for (let i = 1; i <= 5; i++) {
          pages.push({
            type: "page",
            number: i,
          });
        }
        pages.push({
          type: "gap",
          number: "#2",
        });
        pages.push({
          type: "page",
          number: this.totalPages,
        });
      } else {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push({
            type: "page",
            number: i,
          });
        }
      }

      return pages;
    },
  },
  methods: {
    selectPage(page) {
      this.$emit("selected-page", page);
    },
  }
};
</script>

<style lang="less">
.pagination {
  &__item {
    .btn {
      min-width: 50px;
    }
    &.--gap,
    &.--active {
      pointer-events: none;
    }
  }
  &__next_page {
    &.--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
    .btn {
      width: 100%;
    }
  }
}
</style>
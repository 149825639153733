<template>
  <div class="form__group">
    <input
      :type="type"
      :id="id"
      :value="modelValue"
      class="form__input"
      :class="[className, { '--focused': focused }]"
      @input="input"
      :name="name"
      :readonly="readonly"
      :required="required"
      :minlength="minlength"
      :maxlength="maxlength"
      :step="step"
      :pattern="pattern"
      :autocomplete="autocomplete"
      @keydown="keydown"
    />
    <label v-if="label" :for="id" class="form__label">{{
      transformedLabel
    }}</label>
  </div>
</template>
<script>
export default {
  emits: ["update:modelValue", "value-change"],
  name: "InputText",
  props: {
    modelValue: [String, Number],
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    className: String,
    label: {
      type: String,
    },
    required: Boolean,
    readonly: Boolean,
    minlength: {
      type: String,
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    pattern: String,
    autocomplete: String,
    step: [String, Number],
    prevent: Boolean,
    keydown: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    transformedLabel() {
      if (this.$te(this.label)) {
        return this.$t(this.label);
      } else {
        return this.label;
      }
    },
  },
  methods: {
    input(event) {
      if (event.target.value !== "") {
        this.focused = true;
      } else {
        this.focused = false;
      }
      if (!this.readonly) {
        this.$emit("value-change", event.target.value);
        this.$emit("update:modelValue", event.target.value);
      }
    },
  },
  mounted() {
    if (this.modelValue === "") {
      this.focused = false;
    } else {
      this.focused = true;
    }
  },
};
</script>
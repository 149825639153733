import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      plans: [],
      selected: 1,
      is_changeable_flat_fee_to_commission: true,
      end_of_free_period: '0000-00-00'
    }
  },
  getters: {
    plans(state) {
      return state.plans;
    },
    selected(state) {
      return state.selected;
    },
    is_changeable_flat_fee_to_commission(state) {
      return state.is_changeable_flat_fee_to_commission;
    },
    end_of_free_period(state) {
      return state.end_of_free_period;
    },
  },
  mutations: {
    SET_IS_CHANGEABLE_FLAT_FEE_TO_COMMISSION(state, payload) {
      state.is_changeable_flat_fee_to_commission = payload;
    },
    SET_END_OF_FREE_PERIOD(state, payload) {
      state.end_of_free_period = payload;
    },
    SET_SELECTED_PLAN(state, payload) {
      state.selected = payload;
    },
    SET_PLANS(state, { plans }) {
      state.plans = [...plans]
    },
  },
  actions: {
    fetchPlans(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/account/get-billing-plans").then((res) => {
          const plans = [];
          for (const plan in res.data.billing_plans) {
            plans.push(res.data.billing_plans[plan]);
          }
          context.commit("SET_IS_CHANGEABLE_FLAT_FEE_TO_COMMISSION", res.data.is_changeable_flat_fee_to_commission);
          context.commit("SET_END_OF_FREE_PERIOD", res.data.end_of_free_period);
          context.commit("SET_SELECTED_PLAN", res.data.selected.id);
          context.commit("SET_PLANS", { plans });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(res);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        });
      })
    },
    submitPlan(context, { pin, new_billing_plan }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("pin", pin,);
      formData.append("new_billing_plan", new_billing_plan,);
      return new Promise((resolve, reject) => {
        axios.post("/api/account/change-billing-plan", formData).then((res) => {
          if (!res.data.error) {
            context.commit("SET_SELECTED_PLAN", new_billing_plan);
          }
          context.dispatch("toggleLoader", false, { root: true });
          context.dispatch("hideError", true, { root: true });
          resolve(res);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        });
      })
    }
  }
}
<template>
  <div class="form__group">
    <textarea
      :id="id"
      :value="modelValue"
      class="form__input"
      :class="[className, { '--focused': focused }]"
      @input="input"
      :name="name"
      :required="required"
      :rows="rows"
      :minlength="minlength"
      :maxlength="maxlength"
    />
    <label :for="id" class="form__label">{{ $t(label) }}</label>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Textarea",
  props: {
    modelValue: String,
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    className: String,
    label: {
      type: String,
      required: true,
    },
    required: Boolean,
    rows: String,
    minlength: {
      type: [String, Number],
      default: null,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    input(event) {
      if (event.target.value !== "") {
        this.focused = true;
      } else {
        this.focused = false;
      }
      this.$emit("update:modelValue", event.target.value);
    },
  },
  mounted() {
    if (this.modelValue === "") {
      this.focused = false;
    } else {
      this.focused = true;
    }
  },
};
</script>

<style>
</style>
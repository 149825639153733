import smsDetailsModule from './smsDetails';
import axios from "@/axios";

export default {
  modules: {
    smsDetails: smsDetailsModule
  },
  namespaced: true,
  state() {
    return {
      smsDates: {},
      total: {},
      pages: 1,
    }
  },
  getters: {
    all(state) {
      return state.smsDates;
    },
    total(state) {
      return state.total
    },
    pages(state) {
      return state.pages
    },
  },
  mutations: {
    SET_SMS_DATES(state, { smsDates }) {
      state.smsDates = { ...smsDates }
    },
    SET_TOTAL(state, { total }) {
      state.total = { ...total }
    },
    SET_PAGES(state, { pages }) {
      state.pages = { ...pages }
    }
  },
  actions: {
    fetchSmsDates(context, { dateStart, dateEnd }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getSms/from/${dateStart}/to/${dateEnd}/limit/20`
          // , {
          //   from: dateStart,
          //   to: dateEnd,
          //   currency,
          //   group,
          //   page,
          //   limit: 20,
          // }
        ).then((res) => {
          context.commit("SET_SMS_DATES", { smsDates: res.data.value.sms });
          context.commit("SET_TOTAL", { total: res.data.value.summary })
          context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.allRecords) / 20) });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    }
  }
}
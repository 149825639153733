import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      list: []
    }
  },
  getters: {
    all(state) {
      return state.list;
    },
  },
  mutations: {
    SET_INVOICES(state, payload) {
      state.list = [...payload.invoices];
    }
  },
  actions: {
    getInvoices(context, { type }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Invoices/getInvoices/type/${type}`).then(response => {
          if (response.data[type]?.length) {
            context.commit('SET_INVOICES', {
              invoices: [...response.data[type]]
            });
          } else {
            const invoicesArray = [];
            for (let id in response.data[type]) {
              invoicesArray.push(response.data[type][id]);
            }
            context.commit('SET_INVOICES', {
              invoices: invoicesArray
            });
          }

          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch(error => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })

    },
    approveInvoice(context, { id, profoma }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Invoices/approveInvoice/id/${id}/proforma/${profoma !== false}`).then(response => {
          context.dispatch("toggleLoader", false, { root: true });
          resolve(response.data.errno === 0)
        }).catch(error => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },

  },
}
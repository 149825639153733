import axios from "@/axios";

export default {
    namespaced: true,
    state() {
        return {
            setCodeResponse: "",
            getStatusResponse: "",
        }
    },
    getters: {
        setCodeResponse(state) {
            return state.setCodeResponse;
        },
        getStatusResponse(state) {
            return state.getStatusResponse;
        },
    },
    mutations: {
        SET_SET_CODE_RESPONSE(state, { response }) {
            state.setCodeResponse = response;
        },
        SET_GET_STATUS_RESPONSE(state, { response }) {
            state.getStatusResponse = response;
        },
    },
    actions: {
        setCode(context, code) {
            context.dispatch("toggleLoader", true, { root: true });
            const formData = new FormData();
            formData.append("code", code);
            return new Promise((resolve, reject) => {
                axios.post("/api/account/set-code", formData).then((res) => {
                    context.dispatch("toggleLoader", false, { root: true });
                    context.commit("SET_SET_CODE_RESPONSE", { response: res.data.result });
                    resolve(true);
                }).catch((error) => {
                    context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
                    context.dispatch("toggleLoader", false, { root: true });
                    reject(error);
                })
            })
        },
        getStatus(context, generateCode) {
            context.dispatch("toggleLoader", true, { root: true });
            const formData = new FormData();
            formData.append("generate_code", generateCode);
            return new Promise((resolve, reject) => {
                axios.post("/api/account/get-verification-status", formData).then((res) => {
                    context.dispatch("toggleLoader", false, { root: true });
                    context.commit("SET_GET_STATUS_RESPONSE", { response: res.data });
                    resolve(true);
                }).catch((error) => {
                    context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
                    context.dispatch("toggleLoader", false, { root: true });
                    reject(error);
                })
            })
        },
    }
}
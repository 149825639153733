import freshmailDetailsModule from './freshmailDetails';
import axios from "@/axios";

export default {
  modules: {
    freshmailDetails: freshmailDetailsModule
  },
  namespaced: true,
  state() {
    return {
      freshmailDates: {},
      total: {},
    }
  },
  getters: {
    all(state) {
      return state.freshmailDates;
    },
    total(state) {
      return state.total
    }
  },
  mutations: {
    SET_FRESHMAIL_DATES(state, { freshmail }) {
      state.freshmailDates = { ...freshmail }
    },
    SET_TOTAL(state, { total }) {
      state.total = { ...total }
    },
    SET_PAGES(state, { pages }) {
      state.pages = { ...pages }
    }
  },
  actions: {
    fetchFreshmailDates(context, { dateStart, dateEnd }) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/balance/getMails/from/${dateStart}/to/${dateEnd}/limit/20`
          // , {
          //   from: dateStart,
          //   to: dateEnd,
          //   currency,
          //   group,
          //   page,
          //   limit: 20,
          // }
        ).then((res) => {
          context.commit("SET_TOTAL", { total: res.data.summary })
          delete res.data.summary
          context.commit("SET_FRESHMAIL_DATES", { freshmail: res.data });

          context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.allRecords) / 20) });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    }
  }
}
import axios from "@/axios"

export default {
  namespaced: true,
  state() {
    return {
      currentPeriod: "0",
      values: [],
    }
  },
  getters: {
    currentPeriod(state) {
      return state.currentPeriod;
    },
    values(state) {
      return state.values;
    }
  },
  mutations: {
    SET_PERIOD(state, { period }) {
      state.currentPeriod = period;
    },
    SET_VALUES(state, { values }) {
      state.values = [...values];
    }
  },
  actions: {
    fetchPeriod(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get("/api/payments/auto_payoff").then((res) => {
          context.commit("SET_PERIOD", { period: res.data.selected });
          context.commit("SET_VALUES", { values: [...res.data.values] })
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          reject(error);
          context.dispatch("toggleLoader", false, { root: true });
        })
      })
    },
    changePeriod(context, { auto_payoff }) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("auto_payoff", auto_payoff)
      axios.post("/api/payments/set_auto_payoff", formData).then(() => {
        context.commit("SET_PERIOD", { auto_payoff })
        context.dispatch("toggleLoader", false, { root: true });
      }).catch((error) => {
        context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
        context.dispatch("toggleLoader", false, { root: true });
      })
    }
  }
}
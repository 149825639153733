<template>
  <div
    class="service_status__row py-2 d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center"
  >
    <strong class="service_status__label pb-2 pr-2 pr-md-5 flex-shrink-0">{{
      $t("StatusSystemu")
    }}</strong>
    <strong class="service_status__value pb-2" :class="statusFormatted.class">{{
      $t(statusFormatted.literal)
    }}</strong>
  </div>
</template>

<script>
import { formatServiceStatus } from "@/utils/utils";

export default {
  props: ["status"],
  computed: {
    statusFormatted() {
      return formatServiceStatus(this.status);
    },
  },
};
</script>

<style lang="less">
@import "../styles/variables.less";

.system_status {
  border-top: 1px solid @background_border;
  font-size: 1.3rem;
}
</style>
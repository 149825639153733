import axios from "@/axios"
import { createI18n } from 'vue-i18n'


async function translate(lang) {
  return await axios.get(`/locale/${lang}.json?v=${Date.now()}`).then((res) => res);
}

export default function getTranslation(selectedLang) {

  const translationObj = new Promise((resolve, reject) => {
    const literals = {};
    translate('pl').then((transPl) => {
      const pol = transPl.data;
      literals.pol = {
        ...pol.txt
      }
      translate('en').then((transEn) => {
        const eng = transEn.data;
        literals.eng = {
          ...eng.txt
        }
        translate('hu').then((transHu) => {
          const hun = transHu.data;
          literals.hun = {
            ...hun.txt
          }
          const translation = createI18n({
            locale: selectedLang,
            messages: {
              ...literals
            }
          })
          resolve(translation);
        }).catch((error) => {
          reject(error);
        })
      }).catch((error) => {
        reject(error);
      })
    }).catch((error) => {
      reject(error);
    })
  });
  return translationObj;
}
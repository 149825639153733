import axios from "@/axios"

function findIdHelper(currentSubject, subject, id) {
  if (currentSubject.id === id) {

    if (currentSubject.children) {
      subject.hasChildren = true;
      let tempArr = [];
      if (!currentSubject.children.length) {
        for (const child in currentSubject.children) {
          tempArr.push(currentSubject.children[child]);
        }
      } else {
        tempArr = [...currentSubject.children]
      }
      subject.children = [...tempArr];
    } else {
      subject.hasChildren = false;
      subject.name = currentSubject.name;
      subject.id = currentSubject.id;
    }
    return true;
  } else {
    if (currentSubject.children) {
      for (const element in currentSubject.children) {
        const result = findIdHelper(currentSubject.children[element], subject, id);
        if (result) {
          return result;
        }
      }
    }
  }
  return false
}

export default {
  namespaced: true,
  state() {
    return {
      subjects: [],
      dedicated: {},
      foundSubjects: [],
      phraseSearched: "",
    }
  },
  getters: {
    findSubject: (state) => (id) => {
      const subject = {
        hasChildren: null,
        children: [],
        name: "",
      }
      if (id === undefined) {
        subject.hasChildren = true;
        subject.children = state.subjects.map((item) => {
          return {
            name: item.name,
            subtitle: item.subtitle,
            id: item.id,
          }
        });
      } else {
        for (const item of state.subjects) {
          if (item.id === id) {
            if (item.children) {
              subject.hasChildren = true;
              // gdy childrens nie są tablicą
              let tempArr = [];
              if (!item.children.length) {
                for (const child in item.children) {
                  tempArr.push(item.children[child]);
                }
              } else {
                tempArr = [...item.children]
              }
              subject.children = [...tempArr];
            } else {
              subject.hasChildren = false;
              subject.name = item.name;
            }
            break;
          } else {
            const searchResult = findIdHelper(item, subject, id);
            if (searchResult) {
              break;
            }
          }
        }

      }
      return subject;
    },
    dedicated(state) {
      return state.dedicated;
    },
    foundSubjects(state) {
      return state.foundSubjects;
    },
    phraseSearched(state) {
      return state.phraseSearched;
    },
  },
  mutations: {
    SET_DEDICATED(state, { dedicated }) {
      state.dedicated = { ...dedicated };
    },
    SET_SUBJECTS(state, { subjects }) {
      state.subjects = [...subjects];
    },
    SET_FOUND_SUBJECTS(state, { foundSubjects }) {
      state.foundSubjects = { ...foundSubjects };
    },
    SET_PHRASE_SEARCHED(state, { phraseSearched }) {
      state.phraseSearched = phraseSearched;
    }
  },
  actions: {
    fetchList(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get('/api/Support/getDedicatedSupport').then((res) => {
          if (res.data.result != 'error') {
            context.commit("SET_DEDICATED", {dedicated: res.data});
          }
          axios.get('/api/Support/getTopics/flat/false').then((res) => {
            const subjectArray = [];
            if (res.data) {
              for (const item in res.data) {
                subjectArray.push(res.data[item]);
              }
            }
            context.commit("SET_SUBJECTS", { subjects: subjectArray });
            context.dispatch("toggleLoader", false, { root: true });
            resolve(true);
          }).catch((error) => {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
            context.dispatch("toggleLoader", false, { root: true });
            reject(error)
          })
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      });
    },
    searchForPhrase(context, phrase) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Support/searchTopics/search/${phrase}`).then((res) => {
          context.commit("SET_FOUND_SUBJECTS", { foundSubjects: res.data });
          context.commit("SET_PHRASE_SEARCHED", { phraseSearched: phrase });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          context.commit("SET_PHRASE_SEARCHED", { phraseSearched: phrase });
          reject(error)
        })
      });
    }
  },
}
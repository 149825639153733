import axios from "@/axios";
import documentModule from "./document";
import detailsModule from "./details";


export default {
  namespaced: true,
  state() {
    return {
      documents: [],
      agreementStatus: null,
      cessionStatus: null,
      onboardingStatus: null,
      terminationStatus: null,
      kycRequired: true
    }
  },
  getters: {
    all(state) {
      return state.documents;
    },
    agreementStatus(state) {
      return state.agreementStatus;
    },
    cessionStatus(state) {
      return state.cessionStatus;
    },
    onboardingStatus(state) {
      return state.onboardingStatus;
    },
    terminationStatus(state) {
      return state.terminationStatus;
    },
    kycRequired(state) {
      return state.kycRequired;
    },
  },
  mutations: {
    SET_DOCUMENTS(state, { documents }) {
      state.documents = [...documents];
    },
    SET_AGREEMENT_STATUS(state, { agreementStatus }) {
      state.agreementStatus = agreementStatus;
    },
    SET_CESSION_STATUS(state, { cessionStatus }) {
      state.cessionStatus = cessionStatus;
    },
    SET_ONBOARDING_STATUS(state, { onboardingStatus }) {
      state.onboardingStatus = onboardingStatus;
    },
    SET_TERMINATION_STATUS(state, { terminationStatus }) {
      state.terminationStatus = terminationStatus;
    },
    SET_KYC_REQUIRED(state, { kycRequired }) {
      state.kycRequired = kycRequired;
    },
  },
  actions: {
    fetchDocumentsConfig(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get('/api/documents/config').then(response => {
          context.commit('SET_AGREEMENT_STATUS', { agreementStatus: response.data.agreement });
          context.commit('SET_CESSION_STATUS', { cessionStatus: response.data.cession });
          context.commit('SET_ONBOARDING_STATUS', { onboardingStatus: response.data.onboarding });
          context.commit('SET_TERMINATION_STATUS', { onboardingStatus: response.data.termination });
          context.commit('SET_KYC_REQUIRED', { kycRequired: response.data.kycRequired });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    fetchDocuments(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get('/api/documents/list').then(response => {
          if (Array.isArray(response.data)) {

            context.commit('SET_DOCUMENTS', { documents: [...response.data] });
            context.dispatch("toggleLoader", false, { root: true });
            resolve(true);
          } else {
            context.dispatch("toggleLoader", false, { root: true });
            reject(response.data);
          }
        }).catch((error) => {
          if (error.response?.headers["error-message"] !== 'no data') {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
          }
          context.dispatch("toggleLoader", false, {root: true});
          reject(error);
        })
      })
    }
  },
  modules: {
    document: documentModule,
    details: detailsModule,
  }
}
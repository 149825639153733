import axios from "@/axios";
import router from '@/router/index.js';

export default {
  namespaced: true,
  state() {
    return {
      form_id: "1",
      document_type: "",
      agreementMode: null,
      companyData: {
        company_name: "",
        address: "",
        zipcode: "",
        city: "",
        country: "1",
        nip: "",
      },
      personData: {
        name: "",
        pesel: "",
      },
      assigneeCompanyData: {
        company_name: "",
        address: "",
        zipcode: "",
        city: "",
        country: "1143020003",
        nip: "",
      },
      assigneeContactData: {
        name: "",
        email: "",
        phone: "",
      },
      representativesData: {
        board_member: "y",
        representatives: [],
        files: [],
        fileId: 0,
        id: 0,
      },
      additionalSurveyData: {
        survey_reason: "professionalism",
        survey_reason_options: {
          shop: [
            {
              label: "profesjonalnoscIKompleksowoscUslugi",
              value: "professionalism"
            },
            {
              label: "poleceniePrzezInnegoUzytkownika",
              value: "recommendation"
            },
            {
              label: "rozmowaZNaszymiSpecjalistami",
              value: "contact"
            },
            {
              label: "innyPowod",
              value: "other reason"
            }
          ],
          booking: [
            {
              label: "polecenie",
              value: "recommendation"
            },
            {
              label: "cene",
              value: "price"
            },
            {
              label: "warunkiWspolpracy",
              value: "functionalities"
            },
            {
              label: "mozliwoscPrzetestowaniaUslugi",
              value: "tests"
            },
            {
              label: "kontaktZdzialemHandlowymIdoBooking",
              value: "contact"
            },
            {
              label: "innyPowod",
              value: "other reason"
            },
          ],
        },
        survey_reason_complete: "",
        survey_discovered: "recommendation",
        survey_discovered_options: {
          shop: [
            {
              label: "zPoleceniaInnegoUzytkownika",
              value: "recommendation"
            },
            {
              label: "widzialemWczesniejSklepyIdoSellShopIChcialemMiecPodobny",
              value: "internet"
            },
            {
              label: "zReklamyNaFacebookuGoogle",
              value: "social media"
            },
            {
              label: "inneZrodlo",
              value: "other source"
            }
          ],
          booking: [
            {
              label: "zReklamyNaFacebookuGoogle",
              value: "social media"
            },
            {
              label: "informacjiOdPartneraBookingComAirbnbExpediaComNoclegiPlInny",
              value: "partner"
            },
            {
              label: "informacjiZnalezionychWInternecie",
              value: "internet"
            },
            {
              label: "poleceniu",
              value: "recommendation"
            },
            {
              label: "inneZrodlo",
              value: "other source"
            }
          ],
        },
        survey_discovered_complete: "",
        survey_eshops: "",
        survey_eshops_type: "",
      },
      salesData: {
        shops: [],
      },
      beneficiaryData: {
        beneficiaries: [
          {
            nationality: "",
            is_pep: "n",
            name: "",
            id: 0,
            removable: true,
          }
        ],
        id: 1,
      },
      terminationData: {
        period: "2",
        comments: "",
      },
      additionalDocumentsData: {
        url: "",
        name: "",
        value: "",
      },
      registrationDocumentsData: {
        url: "",
        name: "",
        value: "",
      },
      documentLink: "",
      onboardingDisplayLimit: 0
    }
  },
  getters: {
    document_type(state) {
      return state.document_type;
    },
    assigneeCompanyData(state) {
      return state.assigneeCompanyData;
    },
    assigneeContactData(state) {
      return state.assigneeContactData;
    },
    companyData(state) {
      return state.companyData;
    },
    personData(state) {
      return state.personData;
    },
    form_id(state) {
      return state.form_id;
    },
    agreementMode(state) {
      return state.agreementMode;
    },
    salesShops(state) {
      return state.salesData.shops;
    },
    beneficiaries(state) {
      return state.beneficiaryData.beneficiaries;
    },
    beneficiaryId(state) {
      return state.beneficiaryData.id;
    },
    representativesMode(state) {
      return state.representativesData.board_member;
    },
    representativesList(state) {
      return state.representativesData.representatives;
    },
    representativesNumber(state) {
      return state.representativesData.representatives.length;
    },
    representativesId(state) {
      return state.representativesData.id
    },
    survey_reason(state) {
      return state.additionalSurveyData.survey_reason
    },
    survey_reason_options(state) {
      return state.additionalSurveyData.survey_reason_options
    },
    survey_reason_complete(state) {
      return state.additionalSurveyData.survey_reason_complete
    },
    survey_discovered(state) {
      return state.additionalSurveyData.survey_discovered
    },
    survey_discovered_options(state) {
      return state.additionalSurveyData.survey_discovered_options
    },
    survey_discovered_complete(state) {
      return state.additionalSurveyData.survey_discovered_complete
    },
    survey_eshops(state) {
      return state.additionalSurveyData.survey_eshops
    },
    survey_eshops_type(state) {
      return state.additionalSurveyData.survey_eshops_type
    },
    documentLink(state) {
      return state.documentLink;
    },
    representativesNames(state) {
      if (state.agreementMode === 'private' && state.document_type != 'cession_v2') {
        return [{
            name: state.personData.name,
            id: 0,
            valid: true,
          }];
      } else {
        return state.representativesData.representatives.map((item) => {
          return {
            name: item.representative_name,
            id: item.representative_id,
            valid: true,
          };
        });
      }
    },
    terminationPeriod(state) {
      return state.terminationData.period;
    },
    terminationComments(state) {
      return state.terminationData.comments;
    },
    additionalDocumentsData(state) {
      return state.additionalDocumentsData;
    },
    registrationDocumentsData(state) {
      return state.registrationDocumentsData;
    },
    onboardingDisplayLimit(state) {
      return state.onboardingDisplayLimit;
    }
  },
  mutations: {
    SET_ASSIGNEE_COMPANY_DATA(state, payload) {
      state.assigneeCompanyData = { ...state.assigneeCompanyData, ...payload }
    },
    SET_ASSIGNEE_CONTACT_DATA(state, { email, name, phone }) {
      state.assigneeContactData = { email, name, phone }
    },
    SET_DOCUMENT_TYPE(state, { type }) {
      state.document_type = type;
    },
    SET_COMPANY_DATA(state, { companyData }) {
      state.companyData = { ...companyData }
    },
    SET_PERSON_DATA(state, { personData }) {
      state.personData = { ...personData }
    },
    SET_FORM_ID(state, { form_id }) {
      state.form_id = form_id;
    },
    SET_AGREEMENT_MODE(state, { mode }) {
      state.agreementMode = mode;
    },
    ADD_REPRESENTATIVE(state, { representativeData }) {
      state.representativesData.representatives.push({
        ...representativeData
      })
    },
    SAVE_REPRESENTATIVES(state, { representativesArray }) {
      state.representativesData.representatives = [...representativesArray];
    },
    REMOVE_REPRESENTATIVE(state, { representativeId }) {
      state.representativesData.representatives = state.representativesData.representatives.filter((person) => person.representative_id !== representativeId);
    },
    CHANGE_REPRESENTATIVE(state, { representativeId, input_value, url }) {
      state.representativesData.representatives = state.representativesData.representatives.map((person) => {
        if (person.representative_id === representativeId) {
          person.id_iframe_input_value = input_value;
          person.id_iframe_url = url;
        }
        return person;
      });
    },
    SET_REPRESENTATIVES_MODE(state, { mode }) {
      state.representativesData.board_member = mode;
    },
    INCREMENT_REPRESENTATIVES_ID(state) {
      state.representativesData.id++;
    },
    SET_SURVEY_REASON(state, { reason }) {
      state.additionalSurveyData.survey_reason = reason
    },
    SET_SURVEY_REASON_COMPLETE(state, { reason }) {
      state.additionalSurveyData.survey_reason_complete = reason
    },
    SET_SURVEY_DISCOVERED(state, { discovered }) {
      state.additionalSurveyData.survey_discovered = discovered;
    },
    SAVE_SALES_SHOPS(state, { salesShopsArray }) {
      state.salesData.shops = [...salesShopsArray];
    },
    SET_SURVEY_DISCOVERED_COMPLETE(state, { discovered_complete }) {
      state.additionalSurveyData.survey_discovered_complete = discovered_complete;
    },
    SET_SURVEY_ESHOPS(state, { survey_eshops }) {
      state.additionalSurveyData.survey_eshops = survey_eshops;
    },
    SET_SURVEY_ESHOPS_TYPE(state, { survey_eshops_type }) {
      state.additionalSurveyData.survey_eshops_type = survey_eshops_type;
    },
    SET_AGREEMENT_DOCUMENT_LINK(state, { link }) {
      state.documentLink = link;
    },
    INCREMENT_BENEFICIARY_ID(state) {
      state.beneficiaryData.id++
    },
    ADD_BENEFICIARY(state, { beneficiaryData }) {
      state.beneficiaryData.beneficiaries.push({
        ...beneficiaryData
      })
    },
    SAVE_BENEFICIARIES(state, { beneficiariesArray }) {
      state.beneficiaryData.beneficiaries = [...beneficiariesArray];
    },
    REMOVE_BENEFICIARY(state, { beneficiaryId }) {
      state.beneficiaryData.beneficiaries = state.beneficiaryData.beneficiaries.filter((person) => person.id !== beneficiaryId);
    },
    CLEAR_BENEFICIARIES(state) {
      state.beneficiaryData.beneficiaries = [];
    },
    ADD_SHOP(state, { shopData }) {
      for (const shop of state.salesData.shops) {
        if (shop.id == shopData.id) {
          return;
        }
      }
      state.salesData.shops.push({ ...shopData });
    },
    SET_SHOPS_DATA(state, payload) {
      state.salesData.shops = [...payload];
    },
    SET_TERMINATION_COMMENTS(state, { comments }) {
      state.terminationData.comments = comments;
    },
    SET_TERMINATION_PERIOD(state, { period }) {
      state.terminationData.period = period;
    },
    SET_ADDITIONAL_DOCUMENTS(state, { additionalDocumentsData }) {
      state.additionalDocumentsData = {
        ...additionalDocumentsData
      }
    },
    SET_REGISTRATION_DOCUMENTS(state, { registrationDocumentsData }) {
      state.registrationDocumentsData = {
        ...registrationDocumentsData
      }
    },
    SET_ONBOARDING_DISPLAY_LIMIT(state, onboardingDisplayLimit) {
      state.onboardingDisplayLimit = onboardingDisplayLimit
    }
  },
  actions: {
    fetchFormData(context, { type, client_id = null, countries, shops }) {
      context.dispatch("toggleLoader", true, { root: true });
      context.commit("SET_DOCUMENT_TYPE", { type });
      return new Promise((resolve, reject) => {
        axios.post(`/api/documents/creator/document_type/${type}`).then((res) => {
          context.commit("SET_FORM_ID", {
            form_id: res.data.form_id || `${client_id}_${type}`
          });
          context.commit("SET_ONBOARDING_DISPLAY_LIMIT", res.data.onboarding_display_limit);
          if (res.data.last_kyc_data.client_data) {
            const countryFound = countries.find((country) => {
              return country.value === res.data.last_kyc_data.client_data.country_id;
            });
            context.commit("SET_COMPANY_DATA", {
              companyData: {
                ...res.data.last_kyc_data.client_data,
                country: countryFound.value,
              }
            });
            context.commit("SET_PERSON_DATA", {
              personData: {
                ...res.data.last_kyc_data.person_data
              }
            });
          }

          context.commit("SET_REPRESENTATIVES_MODE", { mode: res.data.last_kyc_data.board_member || 'y' });

          context.commit("SET_AGREEMENT_MODE", { mode: res.data.version });

          const shops_array = [];
          for (const shop of shops) {
            shops_array.push({
              name: shop.name,
              id: shop.id,
              category: res.data.last_kyc_data.survey?.profile?.[shop.id] || "",
              month_turnover: res.data.last_kyc_data.survey?.month_turnover?.[shop.id] || "",
              average_value: res.data.last_kyc_data.survey?.average_value?.[shop.id] || "",
              rooms: res.data.last_kyc_data.survey?.rooms || "",
            })
            context.commit("SET_SHOPS_DATA", shops_array);
          }

          const beneficiariesArray = [];
          for (const person of res.data.last_kyc_data.beneficiary) {
            context.commit("INCREMENT_BENEFICIARY_ID");
            beneficiariesArray.push({
              nationality: person.nationality,
              is_pep: person.is_pep !== "" ? person.is_pep : "n",
              name: person.name,
              id: context.getters.beneficiaryId,
            });
          }
          if (beneficiariesArray.length) {
            context.commit("SAVE_BENEFICIARIES", { beneficiariesArray });
          }

          const representativesArray = [];
          for (const person of res.data.last_kyc_data.representatives) {
            context.commit("INCREMENT_REPRESENTATIVES_ID");
            const timestamp = new Date().getTime();
            const id_iframe_input_name = `representative_document__${context.getters.representativesId}_${timestamp}`;

            representativesArray.push({
              representative_id: context.getters.representativesId,
              representative_name: person.name,
              representative_nationality: person.nationality || "1143020003",
              representative_birth_country: person.birth_country || "1143020003",
              representative_birth_date: "",
              representative_pesel: person.pesel,
              representative_document_type: person.document_type || "dowód osobisty",
              representative_document_number: person.document_number,
              representative_document_date_ymd: person.document_date,
              representative_document_without_date: !!person.document_without_date,
              id_iframe_url: "",
              id_iframe_input_value: "",
              id_iframe_input_name,
              provided: false,
            });
          }
          if (representativesArray.length) {
            context.commit("SAVE_REPRESENTATIVES", { representativesArray });
            context.dispatch("fetchRepresentativesIdIframesHelper").then(() => {
              context.dispatch("toggleLoader", false, { root: true });
              context.dispatch("fetchRegistrationDocumentsIframe").then(() => {
                context.dispatch("fetchRepresentativesAdditionalDocumentsIframe").then(() => {
                  resolve(true);
                })
              })
            }).catch((error) => {
              context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
              resolve(true);
              context.dispatch("toggleLoader", false, { root: true });
            });
          } else {
            context.dispatch("toggleLoader", false, { root: true });
            context.dispatch("addRepresentative").then(() => {
              context.dispatch("fetchRegistrationDocumentsIframe").then(() => {
                context.dispatch("fetchRepresentativesAdditionalDocumentsIframe").then(() => {
                  resolve(true);
                })
              })
            });
          }

        }).catch((error) => {
          if (error.response?.headers["error-message"] === 'agreement_not_signed') {
            router.push('/account/documents/agreement');
          } else {
            context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, {root: true});
            context.dispatch("toggleLoader", false, {root: true});
            reject(error);
          }
        })
      });
    },
    fetchAssigneeCompanyData(context, { nip, countries }) {
      context.dispatch("toggleLoader", true, { root: true });
      context.commit("SET_ASSIGNEE_COMPANY_DATA", {
        nip,
      });
      return new Promise((resolve, reject) => {
        axios.get(`/api/documents/get_regon_data/nip/${nip}`).then(res => {
          const countryFound = countries.find((country) => {
            return country.value === res.data.country_value;
          });
          context.commit("SET_ASSIGNEE_COMPANY_DATA", {
            company_name: res.data.company_name,
            address: res.data.address,
            zipcode: res.data.zipcode,
            city: res.data.city,
            country: countryFound.value,
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true);
        }).catch(error => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(true);
        });
      })
    },
    setNewCompanyData(context, payload) {
      context.commit("SET_COMPANY_DATA", payload);
    },
    setNewPersonData(context, payload) {
      context.commit("SET_PERSON_DATA", {personData: payload});
    },
    setAssigneeCompanyData(context, { assigneeCompanyData }) {
      context.commit("SET_ASSIGNEE_COMPANY_DATA", {
        ...assigneeCompanyData,
      });
    },
    fetchRepresentativesAdditionalDocumentsIframe(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        const timestamp = new Date().getTime();
        const additional_documents_iframe_input_value = `${context.getters.form_id}_proxy_2_${timestamp}`;
        axios.get(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${additional_documents_iframe_input_value}`).then((res) => {
          context.commit("SET_ADDITIONAL_DOCUMENTS", {
            additionalDocumentsData: {
              url: res.data.iframe_url,
              value: additional_documents_iframe_input_value,
              name: "additional_documents[]",
            }
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    fetchRegistrationDocumentsIframe(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        const timestamp = new Date().getTime();
        const registration_documents_iframe_input_value = `${context.getters.form_id}_proxy_1_${timestamp}`;
        axios.get(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${registration_documents_iframe_input_value}`).then((res) => {
          context.commit("SET_REGISTRATION_DOCUMENTS", {
            registrationDocumentsData: {
              url: res.data.iframe_url,
              value: registration_documents_iframe_input_value,
              name: "additional_documents[]",
            }
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    fetchRepresentativesIdIframesHelper(context) {
      return new Promise((resolve, reject) => {
        const promisesArray = [];
        for (const person of context.getters.representativesList) {
          promisesArray.push(new Promise((resolveSub, rejectSub) => {
            axios.get(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${person.id_iframe_input_name}`).then((res) => {
              context.commit("CHANGE_REPRESENTATIVE", {
                representativeId: person.representative_id,
                input_value: res.data.input_value,
                url: res.data.iframe_url,
              });
              resolveSub(true)
            }).catch((error) => {
              rejectSub(error);
            });
          }));
        }
        Promise.all(promisesArray).then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    addRepresentative(context) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        context.commit("INCREMENT_REPRESENTATIVES_ID");
        const timestamp = new Date().getTime();
        const id_iframe_input_name = `representative_document__${context.getters.representativesId}_${timestamp}`;
        axios.get(`/api/documents/file_holder/form_id/${context.getters.form_id}/input_id/${id_iframe_input_name}`).then((res) => {
          context.commit("ADD_REPRESENTATIVE", {
            representativeData: {
              representative_id: context.getters.representativesId,
              representative_name: "",
              representative_nationality: "1143020003",
              representative_birth_country: "1143020003",
              representative_birth_date: "",
              representative_pesel: "",
              representative_document_type: "dowód osobisty",
              representative_document_number: "",
              representative_document_date_ymd: "",
              representative_document_without_date: false,
              id_iframe_url: res.data.iframe_url,
              id_iframe_input_value: res.data.input_value,
              id_iframe_input_name,
              provided: false,
            }
          });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    saveRepresentatives(context, { representativesArray }) {
      context.commit("SAVE_REPRESENTATIVES", { representativesArray });
    },
    removeRepresentative(context, { representativeId }) {
      context.commit("REMOVE_REPRESENTATIVE", { representativeId })
    },
    setRepresentativesMode(context, { mode }) {
      context.commit("SET_REPRESENTATIVES_MODE", { mode })
    },
    setSurveyReason(context, { reason }) {
      context.commit("SET_SURVEY_REASON", { reason })
    },
    setSurveyReasonOther(context, { reason }) {
      context.commit("SET_SURVEY_REASON_COMPLETE", { reason })
    },
    setSurveyDiscovered(context, { discovered }) {
      context.commit("SET_SURVEY_DISCOVERED", { discovered })
    },
    setSurveyDiscoveredComplete(context, { discovered_complete }) {
      context.commit("SET_SURVEY_DISCOVERED_COMPLETE", { discovered_complete })
    },
    setSurveyEshops(context, { survey_eshops }) {
      context.commit("SET_SURVEY_ESHOPS", { survey_eshops })
    },
    setSurveyEshopsType(context, { survey_eshops_type }) {
      context.commit("SET_SURVEY_ESHOPS_TYPE", { survey_eshops_type })
    },
    getDocumentLink(context, { formData, client_id }) {
      const linkCreatorObj = {};
      for (let key of formData.keys()) {
        linkCreatorObj[key] = encodeURIComponent(formData.get(key));
      }
      let payLink = '';
      let link = JSON.stringify(linkCreatorObj)
        .replaceAll('","', "&")
        .replace("{", "")
        .replace("}", "")
        .replaceAll('"', "")
        .replaceAll(":", "=");

      let links = {};
      payLink = link + `&document_type=${context.getters.document_type}&client_id=${client_id}`
      links.link = "/api/documents/document_template/?" + payLink

      if (context.getters.document_type == 'agreement_v2') {
        payLink = link +`&document_type=idopayment_agreement&client_id=${client_id}`
        links.iDoPaymentsLink = "/api/documents/document_template/?" + payLink
      }

      context.commit("SET_AGREEMENT_DOCUMENT_LINK", {
        link: links,
      });
    },
    submitDocumentForm(context, { formData }) {
      context.dispatch("toggleLoader", true, { root: true });
      formData.append("document_type", context.getters.document_type);
      formData.append("form_id", context.getters.form_id);
      return new Promise((resolve, reject) => {
        axios.post("/api/documents/add", formData).then((res) => {
          context.dispatch("toggleLoader", false, { root: true });
          document.querySelectorAll('.id_iframe_message').forEach((elem) => {
            elem.contentWindow.postMessage({ event_id: 'send' }, "*")
          })
          if (res.data.result === "error") {
            reject({ error: res.data.error });
          }
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    },
    saveSalesShops(context, { salesShopsArray }) {
      context.commit("SAVE_SALES_SHOPS", { salesShopsArray })
    },
    addBeneficiary(context, payload) {
      const beneficiaryInsertData = payload ? payload.beneficiaryInsertData : {};
      context.commit("INCREMENT_BENEFICIARY_ID");
      context.commit("ADD_BENEFICIARY", {
        beneficiaryData: {
          beneficiary_nationality: beneficiaryInsertData.nationality || "1143020003",
          beneficiary_is_pep: "n",
          beneficiary_name: beneficiaryInsertData.name || "",
          removable: typeof beneficiaryInsertData.removable === "boolean" ? beneficiaryInsertData.removable : true,
          id: context.getters.beneficiaryId,
        }
      });
    },
    removeBeneficiary(context, { beneficiaryId }) {
      context.commit("REMOVE_BENEFICIARY", { beneficiaryId });
    },
    clearBeneficiaries(context) {
      context.commit("CLEAR_BENEFICIARIES");
    },
    saveBeneficiaries(context, { beneficiariesArray }) {
      context.commit("SAVE_BENEFICIARIES", { beneficiariesArray });
    },
    sendTerminationData(context, { comments, period }) {
      context.commit("SET_TERMINATION_COMMENTS", { comments });
      context.commit("SET_TERMINATION_PERIOD", { period });
    }
  }
}
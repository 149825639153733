<template>
  <div class="additional_nav d-none d-md-block">
    <a v-if="$mode !== 'shoprenter'" class="mr-3" :href="$panelUrl">{{ $t("PANELAdministracyjny") }}</a>
    <a
      href="#logout"
      @click.prevent="logoutUser({ lang: $lang, mode: $mode })"
      >{{ $t("Wyloguj") }} {{$user.login}}</a
    >
    <slot></slot>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    menuClass: String,
  },
  methods: {
    ...mapActions({
      logoutUser: "logoutUser",
    }),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/variables.less";

.additional_nav {
  a {
    color: @background_color_text;
  }
}
</style>
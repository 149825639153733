<template>
  <tr class="table__row">
    <td class="table__cell px-2 py-3">
      <strong>{{ formattedType }}</strong>
    </td>
    <td class="table__cell px-2 py-3">
      <span>{{ addedBy }}</span>
    </td>
    <td class="table__cell --center px-2 py-3">
      <span>{{ additionDate }}</span>
    </td>
    <td class="table__cell --center px-2 py-3">
      <span>{{ $helpers.capFirstLetter(formattedStatus) }}</span>
    </td>
    <td class="table__cell --center px-2 py-3">
      <router-link :to="`/account/documents/details/${id}`">{{
        $t("Zobacz")
      }}</router-link>
    </td>
  </tr>
</template>

<script>
import { formatDocumentType, formatDocumentStatus } from "@/utils/utils";
export default {
  props: ["type", "addedBy", "additionDate", "status", "id"],
  computed: {
    formattedType() {
      return this.$t(formatDocumentType(this.type, this.$mode));
    },
    formattedStatus() {
      return this.$t(formatDocumentStatus(this.status));
    },
  },
};
</script>
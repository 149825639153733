export default {
  install: (app, { currencies, billing_currency }) => {
    app.config.globalProperties.$formatPrice = (value, currency) => {
      let currencySelected, currencyFoundByName;

      // sprawdzenie, gdyby currency to była nazwa, nie ID
      for (const id in currencies) {
        if (currencies[id].currency_name === currency) {
          currencyFoundByName = id;
        }
      }

      if (currency && currencies[currency]) {
        currencySelected = currency;
      } else if (currencyFoundByName) {
        currencySelected = currencyFoundByName;
      } else {
        currencySelected = billing_currency;
      }

      // ? Funkcja zaadaptowana z template Shop, do ewentualnego refactoru

      function formatPrice(valuePrice, configObj) {
        var obj = configObj;
        var v = valuePrice;
        var m = obj.currency_format;
        if (obj.currency_grouping_separator)
          m = m.replace(",", obj.currency_grouping_separator);
        if (obj.currency_decimal_separator)
          m = m.replace(".", obj.currency_decimal_separator);
        var currency_symbol = obj.currency_symbol;
        var currency_space = obj.currency_space ? ' ' : '';
        var currency_before_value = obj.currency_before_value ? true : false
        if (!m || isNaN(+v)) {
          return v;
        }
        v = m.charAt(0) == '-' ? -v : +v;
        var isNegative = v < 0 ? v = -v : 0;
        var result = m.match(/[^\d\-+#]/g);
        var Decimal = (result && result[result.length - 1]) || '.';
        var Group = (result && result[1] && result[0]) || ',';
        m = m.split(Decimal);
        v = v.toFixed(m[1] && m[1].length);
        v = +(v) + '';
        var pos_trail_zero = m[1] && m[1].lastIndexOf('0');
        var part = v.split('.');
        if (!part[1] || part[1] && part[1].length <= pos_trail_zero) {
          v = (+v).toFixed(pos_trail_zero + 1);
        }
        var szSep = m[0].split(Group);
        m[0] = szSep.join('');
        var pos_lead_zero = m[0] && m[0].indexOf('0');
        if (pos_lead_zero > -1) {
          while (part[0].length < (m[0].length - pos_lead_zero)) {
            part[0] = '0' + part[0];
          }
        } else if (+part[0] == 0) {
          part[0] = '';
        }
        v = v.split('.');
        v[0] = part[0];
        var pos_separator = (szSep[1] && szSep[szSep.length - 1].length);
        if (pos_separator) {
          var integer = v[0];
          var str = '';
          var offset = integer.length % pos_separator;
          for (var i = 0, l = integer.length; i < l; i++) {
            str += integer.charAt(i);
            if (!((i - offset + 1) % pos_separator) && i < l - pos_separator) {
              if (Group === ' ') {
                //twarda spacja zeby ceny nie rozjezdzaly sie w kolejnych liniach
                Group = String.fromCharCode(160);
              }
              str += Group;
            }
          }
          v[0] = str;
        }
        v[1] = (m[1] && v[1]) ? Decimal + v[1] : "";
        var price = (isNegative ? '-' : '') + v[0] + v[1];
        price = currency_before_value ? currency_symbol + currency_space + price : price + currency_space + currency_symbol;
        return price;
      }

      return formatPrice(value, currencies[currencySelected]);
    }

  }
}
<template>
  <div class="table__row">
    <div class="table__cell p-4">
      <span>{{ number }}</span>
    </div>
    <div class="table__cell p-4">
      <span v-if="overdue">{{ $t("przeterminowana") }}</span>
      <span v-else>{{ duedate }}</span>
    </div>
    <div class="table__cell p-4">
      <span>{{ setdate }}</span>
    </div>
    <div class="table__cell p-4">
      <span>{{ $formatPrice(gros_amount, billing_currency) }}</span>
    </div>
    <div class="table__cell p-4">
      <router-link :to="`/invoices/details/${invoice_id}/proforma/${pf !== false}/idopayments/${idopayments ? '1' : '0'}`">{{
        $t("Szczegoly")
      }}</router-link>
    </div>
    <div v-if="approvaltime != '0000-00-00 00:00:00'" class="table__cell p-4">
      <div v-if="idopayments">
        <a
            class="d-block"
            target="__blank"
            :href="pdf_url"
        >{{ $t("Oryginal") }}</a
        >
        <a
            target="__blank"
            :href="`/api/Invoices/getPdf/id/${invoice_id}/duplicate/true/proforma/${pf}/idopayments/1`"
        >{{ $t("Duplikat") }}</a
        >
      </div>
      <div v-else>
        <a
          class="d-block"
          target="__blank"
          :href="`/api/Invoices/getPdf/id/${invoice_id}/duplicate/false/proforma/${pf}`"
          >{{ $t("Oryginal") }}</a
        >
        <a v-if="$mode !== 'shoprenter'"
          target="__blank"
          :href="`/api/Invoices/getPdf/id/${invoice_id}/duplicate/true/proforma/${pf}`"
          >{{ $t("Duplikat") }}</a
        >
      </div>
    </div>
    <div v-else class="table__cell p-4">
      <a
          href="#"
          @click="confirm()"
      >{{ $t("Zatwierdz") }}</a
      >
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  emits: [
      "setApprovaltime"
  ],
  props: [
    "invoice_id",
    "number",
    "duedate",
    "setdate",
    "gros_amount",
    "billing_currency",
    "overdue",
    "approvaltime",
    "pf",
    "idopayments",
    "pdf_url",
  ],
  methods: {
    ...mapActions({
      approveInvoice: "invoicesList/approveInvoice",
    }),
    confirm() {
      if (this.approveInvoice( {id: this.invoice_id, profoma: this.pf} )) {
        this.$emit("setApprovaltime");
      }
    }
  }
};
</script>

<style>
</style>
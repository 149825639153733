export default [
  {
    name: "StronaGlowna",
    path: "/",
    icon: "dashboard",
    perms: [],
  },
  {
    name: "CentrumSupportu",
    path: "/support",
    icon: "message",
    perms: []
  },
  {
    name: "Wydatki",
    path: "/balance",
    icon: "balance",
    perms: ["admin", "payments", "iai_orders", "finances"],
    children: [
      {
        name: "WplataSrodkow",
        path: "/payments",
        perms: ["admin", "payments"]
      },
      {
        name: "HistoriaWydatkowIZasilen",
        path: "/history",
        perms: ["admin", "payments"]
      },
      {
        name: "ZadaniaDoZatwierdzenia",
        path: "/confirm_tasks",
        perms: ["admin", "payments", "iai_orders", "finances"]
      },
      {
        name: "RozliczeniaUslugDodatkowych",
        path: "/additional_services",
        perms: ["admin", "payments"],
        children: [
          {
            name: "SMS",
            path: "/sms",
            perms: ["admin", "payments"],
          },
          {
            name: "Freshmail",
            path: "/freshmail",
            perms: ["admin", "payments"],
          },
          {
            name: "IdoPay",
            path: "/idopay",
            perms: ["admin", "payments"],
          }
        ]
      },
    ],
  },
  {
    name: "Faktury",
    path: "/invoices",
    icon: "invoice",
    perms: ["admin", "payments"]
  },
  {
    name: "Wplaty",
    path: "/payments",
    icon: "chart",
    perms: ["admin", "payments"],
    children: [
      {
        name: "ListaWplat",
        path: "/list",
        perms: ["admin", "payments"]
      },
      {
        name: "WyplataSrodkow",
        path: "/withdrawal",
        perms: ["admin", "payments"],
        children: [
          {
            name: "IdoPay",
            path: "/idopay",
            perms: ["admin", "payments"]
          }
        ]
      },
      {
        name: "HistoriaWyplat",
        path: "/withdrawal_history",
        perms: ["admin", "payments"]
      },
      {
        name: "UstawieniaWyplat",
        path: "/withdrawal_settings",
        perms: ["admin", "payments"]
      }
    ]
  },
  {
    name: "TwojeKonto",
    path: "/account",
    icon: "user",
    perms: [],
    children: [
      {
        name: "StanDzialaniaUslugi",
        path: "/service_status",
        perms: []
      },
      {
        name: "Dokumenty",
        path: "/documents",
        perms: ["admin"]
      },
      {
        name: "ZarzadzanieKontem",
        path: "/settings",
        perms: ["admin"]
      },
      {
        name: "Abonament",
        path: "/subscription",
        perms: ["admin", "iai_orders", "finances"]
      },
    ]
  },
]
import axios from "axios";
import {version} from "../../package.json";

const instance = axios.create({
  timeout: 500000,
  headers: {
    'APP-VERSION': version,
  }
});

instance.defaults.withCredentials = true;
instance.defaults.xsrfCookieName = "X-CSRF-TOKEN";
instance.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

instance.interceptors.response.use(function (config) {
  return config;
}, function (error) {
  if (error.response.status === 401) {
    let get = '';

    if (/^\/support\/contact_form\/\d+\/new_ticket$/.test(window.location.pathname)) {
      let params = [];
      const availableParams = ['title', 'ticket_title', 'ticket_content'];
      const splittedGet = window.location.search.split('&');

      splittedGet.forEach(function(element) {
        const paramAndValue = element.split('=');
        paramAndValue[0] = paramAndValue[0].replace('?', '');

        if (availableParams.includes(paramAndValue[0])) {
          params.push(paramAndValue[0] + '=' + paramAndValue[1]);
        }
      });

      if (params.length) {
        get = '?' + encodeURIComponent(params.join('&'));
      }
    }

    window.location.href = `/api/?ref=${window.location.pathname + get}`
  } else {
    return Promise.reject(error);
  }
});

export default instance;
<template>
  <header class="d-flex justify-content-between align-items-center">
    <div id="logo" class="pl-3 pl-md-0">
      <router-link class="d-flex" to="/"
        ><img :src="logoSrc" :alt="logoAlt"
      /></router-link>
    </div>
    <AdditionalNav :menuClass="'d-none d-md-inline'"></AdditionalNav>
  </header>
</template>

<script>
import AdditionalNav from "./AdditionalNav";

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Header",
  components: {
    AdditionalNav,
  },
  computed: {
    logoSrc() {
      switch (this.$mode) {
        case 'booking':
          return "/img/idobooking-logo.svg";
        case 'shoprenter':
          return "/img/shoprenter-logo.svg";
      }
      return "/img/idosell-logo.svg";
    },
    logoAlt() {
      return this.$mode === "shop" ? "IdoShop" : "IdoBooking";
    },
  },
};
</script>

<style lang="less">
@import "../../styles/variables.less";
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: @background;
  z-index: 2;
  height: 64px;

  @media @laptop {
    width: 100%;
    position: relative;
    padding: 20px 0;
    height: auto;
  }

  #logo img {
    height: 40px;
    @media @laptop {
      height: 50px;
    }
  }
}
</style>
import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './translation';
import formatPrice from './plugins/formatPrice';
import helpers from "./plugins/helpers";
import './utils/global.js';
import './styles/app.less';
import fetchConfig from './user'
import baseComponents from '@/components/base'

/* fix na safari dla niedostepnego requestSubmit (polyfill)
być może da sie jakos to zalaczyc lepiej do vue, ale ciezko znalezc
https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit
*/
require('form-request-submit-polyfill');

if (process.env.NODE_ENV === 'production') {
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "iipbp1d0rm");
}

fetchConfig().then((data) => {
  i18n(data.config.language).then((translation) => {
    const app = createApp(App);

    app.config.globalProperties.$currencies = data.config.currencies;
    app.config.globalProperties.$currency = data.config.billing_currency;
    app.config.globalProperties.$vat_rate = data.config.vat_rate;
    app.config.globalProperties.$lang = data.config.language;
    app.config.globalProperties.$currencySymbol = data.config.currencies[data.config.billing_currency].currency_symbol;
    app.config.globalProperties.$currencyName = data.config.currencies[data.config.billing_currency].currency_name;
    app.config.globalProperties.$mode = data.user.user_type;
    app.config.globalProperties.$panelType = data.user.panel_type;
    app.config.globalProperties.$panelUrl = data.config.panel;
    app.config.globalProperties.$user = {
      ...data.user
    };
    app.config.globalProperties.$service = data.config.service;
    app.config.globalProperties.companyShortname = data.config.company_shortname;
    const countriesArray = [];
    for (const country in data.config.countries) {
      countriesArray.push(data.config.countries[country]);
    }
    app.config.globalProperties.$countries = [...countriesArray];
    app.config.globalProperties.$nationalities = [...data.config.nationalities];
    app.config.globalProperties.$categories = [...data.config.categories];
    app.config.globalProperties.$shops = [...data.config.shops];
    app.config.globalProperties.$phone_prefix = [...data.config.phone_prefix];
    app.config.globalProperties.$panel_installation_date = data.config.panel_installation_date;
    app.config.globalProperties.$versionDocument = data.config.versionDocument;
    app.config.globalProperties.$terminateEnabled= data.config.terminate_enabled;
    app.config.globalProperties.$max_file_uploads = data.config.max_file_uploads;
    app.config.globalProperties.$upload_max_filesize = data.config.upload_max_filesize.replace(/\D/g, '');
    app.config.globalProperties.$post_max_size = data.config.post_max_size.replace(/\D/g, '');
    app.config.globalProperties.$available_file_extensions = data.config.available_file_extensions;

    baseComponents.forEach(component => {
      app.component(component.name, component);
    });

    app.use(store).use(router).use(translation).use(formatPrice, { ...data.config }).use(helpers).mount('#app');

  }).catch((error) => {
    console.log("Nieudane pobieranie tłumaczeń - ", error);
  })
}).catch((error) => {
  console.log("Nieudane pobieranie configa - ", error);
})
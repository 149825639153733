<template>
  <div
    v-if="backgroundClose"
    class="modal__backdrop"
    :class="{ '--dimmed': dimmed, '--center_parent': centerParent }"
    @click="closeModal"
  ></div>
  <div
    class="modal"
    :class="[
      className,
      {
        '--right': right,
        '--left': left,
        '--center': center,
        '--fixed': fixed,
        '--top': top,
        '--center_parent': centerParent,
      },
    ]"
  >
    <div class="modal__close" @click="closeModal">✕</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ["modal-close"],
  name: "Modal",
  props: {
    className: String,
    dimmed: Boolean,
    left: Boolean,
    right: Boolean,
    center: Boolean,
    fixed: Boolean,
    top: Boolean,
    backgroundClose: Boolean,
    centerParent: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("modal-close");
    },
  },
};
</script>

<style lang="less">
@import "../../styles/variables.less";
.modal {
  position: absolute;
  z-index: 2;
  background: @background;
  padding: 30px 15px 15px;
  box-shadow: @box-shadow_hover;
  width: calc(100vw - 30px);
  @media (max-width: 757px) {
    top: 50% !important;
    left: 50% !important;
    position: fixed;
    transform: translate(-50%, -50%) !important;
  }
  @media @tablet {
    width: auto;
    padding: 30px;
    min-width: 500px;
  }
  &.--right {
    right: 0;
    left: auto;
  }
  &.--left {
    right: auto;
    left: 0;
  }
  &.--center {
    left: 50%;
    transform: translateX(-50%);
  }
  &.--top {
    top: 0;
  }
  &.--fixed {
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
  }
  &.--center_parent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__backdrop {
    position: fixed;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @media (max-width: 757px) {
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(5px);
    }
    &.--dimmed {
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(5px);
    }
    &.--center_parent {
      position: absolute;
    }
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .payment_details {
    &__title {
      font-size: 2rem;
      text-align: left;
    }
    &__label {
      font-size: 1.2rem;
      color: @background_color_grey;
      text-align: left;
    }
    &__content:not(:last-child) {
      border-bottom: 1px solid @background_border;
    }
    &__modal {
      @media @tablet {
        min-width: 435px;
      }
    }
  }
}
</style>
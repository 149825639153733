import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      payments: [],
      summary: {},
      pages: 0,
      addRefundStatus: {
        error: false,
        success: false,
        errorCode: 4,
        errorMessage: ''
      }
    }
  },
  getters: {
    payments(state) {
      return state.payments;
    },
    pages(state) {
      return state.pages
    },
    summary(state) {
      return state.summary
    },
    addRefundStatus(state) {
      return state.addRefundStatus;
    }
  },
  mutations: {
    SET_PAYMENTS(state, { payments }) {
      state.payments = [...payments];
    },
    SET_SUMMARY(state, { totalPayments, totalComission, totalCount }) {
      state.summary = {
        totalPayments,
        totalComission,
        totalCount
      }
    },
    SET_PAGES(state, { pages }) {
      state.pages = pages
    },
    SET_ADD_REFUND_STATUS(state, {addRefundStatus}) {
      state.addRefundStatus = {...addRefundStatus};
    }
  },
  actions: {
    setPayments(context, { from, to, order_id, payment_id, page, commision_type, resetRefundStatus, payment_status, date_type}) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Balance/getCommissions/from/${from}/to/${to}/order/${order_id}/payment/${payment_id}/limit/20/page/${page}/commision_type/${commision_type}/status/${payment_status}/date_type/${date_type}`).then((res) => {
          context.commit("SET_PAYMENTS", { payments: res.data.data });
          context.commit("SET_SUMMARY", {
            totalPayments: res.data.summary.amount,
            totalComission: res.data.summary.commissions,
            totalCount: res.data.summary.payments
          });
          context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.count) / 20) });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
          if (resetRefundStatus) {
            context.commit('SET_ADD_REFUND_STATUS', {addRefundStatus: {
                error: false,
                success: false,
                errorCode: 0,
                errorMessage: ''
              }});
          }
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
    addIdoPayRefund(context, {donation_id, external_number, amount, currency, system, external_payment_number}) {
      context.dispatch("toggleLoader", true, { root: true });
      const formData = new FormData();
      formData.append("donation_id", donation_id);
      formData.append("external_number", external_number);
      formData.append("amount", amount);
      formData.append("currency", currency);
      formData.append("system", system);
      formData.append("external_payment_number", external_payment_number);

      return new Promise((resolve, reject) => {
        axios.post("/api/Balance/addIdoPayRefund", formData).then((res) => {
          if (res.data.success) {
            context.commit('SET_ADD_REFUND_STATUS', {addRefundStatus: {
                error: false,
                success: true,
                errorCode: '',
                errorMessage: ''
              }});
          } else {
            context.commit('SET_ADD_REFUND_STATUS', {addRefundStatus: {
                error: true,
                success: false,
                errorCode: res.data.errorCode,
                errorMessage: res.data.error
              }});
          }
          context.dispatch("toggleLoader", false, {root: true});
          resolve(true);
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error);
        })
      })
    }
  }
}
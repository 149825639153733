<template>
  <footer class="footer">
    <div
        class="container d-flex justify-content-center align-items-center py-4 px-3"
    >
      <div class="copyright">
        ©2000-{{ currentYear }} {{ $service }}<span> ({{ companyShortname }})</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      const today = new Date();
      return today.getFullYear();
    },
  },
};
</script>

<style lang="less">
@import "../../styles/variables.less";

.footer {
  margin-top: auto;
  background: @background_dark;
  .account {
    a {
      color: @background_dark_color_text;
      &:hover {
        color: @background_dark_color_link;
      }
    }
  }

  .copyright {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: @background_dark_color_text;
    span {
      white-space: nowrap;
    }
  }

  .social_media {
    text-align: right;

    @media @laptop {
      text-align: center;
    }

    &__link {
      color: @background_dark_color_text;
      padding-left: 2px;
      padding-right: 2px;
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 3rem;

      @media @tablet {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
<template>
  <div class="form__box">
    <input
      type="checkbox"
      :id="id"
      :checked="modelValue"
      class="form__input"
      :class="className"
      @input="input"
      :name="name"
      :required="required"
      :value="value"
    />
    <label :for="id" class="form__label"><slot></slot></label>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "value-change"],
  name: "InputCheckbox",
  props: {
    modelValue: Boolean,
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: String,
    className: String,
    required: Boolean,
  },
  methods: {
    input(event) {
      this.$emit("update:modelValue", event.target.checked);
      this.$emit("value-change", event.target.checked);
    },
  },
};
</script>

<style>
</style>
/* eslint-disable no-useless-escape */
import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      details: {},
      pos: {},
      idp: false,
      groups: {},
      groupDetails: {},
    }
  },
  getters: {
    number(state) {
      return state.details.number;
    },
    pos(state) {
      return state.pos;
    },
    idp(state) {
      return state.idp;
    },
    currency(state) {
      return state.details.billing_currency;
    },
    payment_type(state) {
      return state.details.payment_type;
    },
    duedate(state) {
      return state.details.duedate;
    },
    setdate(state) {
      return state.details.setdate;
    },
    paiddate(state) {
      return state.details.paiddate;
    },
    status(state) {
      return state.details.status;
    },
    approvaltime(state) {
      return state.details.approvaltime;
    },
    remaining(state) {
      return state.details.remaining;
    },
    groups(state) {
      return state.groups;
    }   ,
    group_details(state) {
      return state.groupDetails;
    }
  },
  mutations: {
    SET_DETAILS(state, { details }) {
      state.details = { ...details };
    },
    SET_POS(state, { pos }) {
      state.pos = { ...pos };
    },
    SET_IDP(state, { idp }) {
      state.idp = idp;
    },
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },
    SET_GROUP_DETAILS(state, groupDetails) {
      state.groupDetails = groupDetails;
    },
  },
  actions: {
    getInvoice(context, { id, proforma, idopayments }) {
      context.dispatch("toggleLoader", true, { root: true });
      axios.get(`/api/Invoices/getInvoice/id/${id}/proforma/${proforma}/idopayments/${idopayments}`).then(response => {
        const details = response.data;
        const pos = { ...details.pos };
        const idp = response.data.idopayments;
        delete details.pos;
        context.dispatch("toggleLoader", false, { root: true });
        context.commit('SET_DETAILS', { details });
        context.commit('SET_POS', { pos });
        context.commit('SET_IDP', { idp });
      }).catch(error => {
        context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
        context.dispatch("toggleLoader", false, { root: true });
      })
    },
    getInvoiceDetails(context, { id, proforma, group, idopayments }) {
      context.dispatch("toggleLoader", true, { root: true });
      axios.get(`/api/Invoices/getInvoiceDetails/id/${id}/proforma/${proforma}/idopayments/${idopayments ?? 0}/group/${group}`).then(response => {
        if (group == '') {
          context.commit('SET_GROUPS', response.data.result === 'error' ? {} : response.data);
        } else {
          context.commit('SET_GROUP_DETAILS', response.data.result === 'error' ? {} : response.data);
        }
        context.dispatch("toggleLoader", false, { root: true });
      }).catch(error => {
        context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
        context.dispatch("toggleLoader", false, { root: true });
      })
    }
  },
}
export default {
    install: (app) => {
        app.config.globalProperties.$helpers = {

            //miejsce do robienia globalnych helperowych typowo JS'owych funkcji
            //użycie w widoku: {{ $helpers.capFirstLetter("wartosc") }}


            /**
             * Zmienia w literale pierwsza litere na duza (lekkie klamstwo, bo najpierw na lower case leci)
             *
             * @param val literal do zmiany
             * @returns {string} zmieniona literal
             */
            capFirstLetter(val) {
                return val.toLowerCase().charAt(0).toUpperCase() + val.slice(1);
            },

        }
    }
};
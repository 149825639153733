import axios from "@/axios";

export default {
  namespaced: true,
  state() {
    return {
      packages: {},
      summary: {},
      pages: 0,
    }
  },
  getters: {
    packages(state) {
      return state.packages;
    },
    pages(state) {
      return state.pages
    },
    summary(state) {
      return state.summary;
    }
  },
  mutations: {
    SET_PACKAGES(state, { packages }) {
      state.packages = { ...packages };
    },
    SET_SUMMARY(state, { totalBaseCost, totalAdditional, totalCount, shoprenterCurrency }) {
      state.summary = {
        totalBaseCost,
        totalAdditional,
        totalCount,
        shoprenterCurrency
      }
    },
    SET_PAGES(state, { pages }) {
      state.pages = pages
    }
  },
  actions: {
    setPackages(context, { from, to, number, balance, type, courier, page}) {
      context.dispatch("toggleLoader", true, { root: true });
      return new Promise((resolve, reject) => {
        axios.get(`/api/Balance/getPackages/from/${from}/to/${to}/balance/${balance}/type/${type}/courier/${courier}/page/${page}/number/${number}`).then((res) => {
          context.commit("SET_PACKAGES", { packages: res.data.items })
          context.commit("SET_SUMMARY", {
            totalAdditional: res.data.total,
            totalBaseCost: res.data.total_cost,
            totalCount: res.data.count,
            shoprenterCurrency: res.data.shoprenter_currency
          });
          context.commit("SET_PAGES", { pages: Math.ceil(parseInt(res.data.count) / 20) });
          context.dispatch("toggleLoader", false, { root: true });
          resolve(true)
        }).catch((error) => {
          context.dispatch("internalServerError", {code: error.response?.headers["error-message"]}, { root: true });
          context.dispatch("toggleLoader", false, { root: true });
          reject(error)
        })
      })
    },
  }
}